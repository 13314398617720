import IconYoutube from "../assets/svg/logos_youtube-icon.svg";
import IconFacebook from "../assets/svg/logos_facebook.svg";
import IconTiktok from "../assets/svg/logos_tiktok-icon.svg";
import IconLinkedIn from "../assets/svg/akar-icons_linkedin-box-fill.svg";
import IconInstagram from "../assets/svg/instagram.svg";
import IconTwitter from "../assets/svg/twitter.svg";

export const encodeQueryData = (data: any) => {
  let ret = [];

  for (let d in data) {
    let value = data[d];

    if (typeof value == "object") {
      value = data[d].join(",");
    }

    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(value));
  }

  return ret.join("&");
};

export const formatRp = (currency: any) => {
  let v = String(currency).replaceAll(",", "").replaceAll(".", "").split("");
  let arr = [];
  while (v.length) {
    arr.unshift(v.splice(-3).join(""));
  }
  return arr.join(".");
};

export const getRp = (value: any) => {
  return value
    .split("-")
    .map((o: string) => `Rp. ${Number(o).toLocaleString("id-ID")}`)[0];
};

export const componentStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case "submission":
      return <p className="text-sm font-semibold text-[#FD7900]">{status}</p>;
    case "on going":
      return <p className="text-sm font-semibold text-[#0097DA]">{status}</p>;
    case "completed":
      return <p className="text-sm font-semibold text-[#00AC07]">{status}</p>;

    default:
      return <p className="text-sm font-semibold text-[#FD7900]">{status}</p>;
  }
};

export const componentStatusEarning = (status: string) => {
  switch (status.toLowerCase()) {
    case "process":
      return <p className="text-sm font-semibold text-[#FD7900]">{status}</p>;
    case "succeed":
      return <p className="text-sm font-semibold text-[#00AC07]">{status}</p>;
    case "available":
      return <p className="text-sm font-semibold text-[#0097DA]">{status}</p>;

    // default:
    //   return <p className="text-sm font-semibold text-[#FD7900]">{status}</p>;
  }
};

export const listCategoryJob = [
  "All",
  "Submission",
  "On Going",
  "Completed",
  "Canceled",
];

export const getFileExt = (file: string) => {
  const arr = file.split(".");
  return arr[arr.length - 1];
};

export const isValidUrl = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const nFormat = (num: string | number) => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(Number(num));
};

export const getIcon: Record<string, string> = {
  Facebook: IconFacebook,
  LinkedIn: IconLinkedIn,
  Youtube: IconYoutube,
  Instagram: IconInstagram,
  Tiktok: IconTiktok,
  Twitter: IconTwitter,
};
