import IconIG from "../../assets/svg/instagram.svg"
import IconYoutube from "../../assets/svg/youtube-circle.svg";
import Facebook from "../../assets/svg/facebook.svg";
import Twitter from "../../assets/svg/twitter.svg";
import Linkedin from "../../assets/svg/akar-icons_linkedin-box-fill.svg";
import Tiktok from "../../assets/svg/logos_tiktok-icon.svg"
import { imageError } from "../../helpers/brokenImg";

export default function platForm(name: string) {
  if (name) {
    if (name.toLowerCase() === "instagram") {
      return (
        <img
          src={IconIG}
          alt="youtube"
          className="pr-2 h-10 w-10"
          onError={(e) => imageError(e)}
        />
      );
    } else if (name.toLowerCase() === "facebook") {
      return (
        <img
          src={Facebook}
          alt="youtube"
          className="pr-2 h-10 w-10"
          onError={(e) => imageError(e)}
        />
      );
    } else if (name.toLowerCase() === "twitter") {
      return (
        <img
          src={Twitter}
          alt="youtube"
          className="pr-2 h-10 w-10"
          onError={(e) => imageError(e)}
        />
      );
    } else if (name.toLowerCase() === "linkedin") {
      return (
        <img
          src={Linkedin}
          alt="youtube"
          className="pr-2 h-10 w-10"
          onError={(e) => imageError(e)}
        />
      );
    } else if (name.toLowerCase() === "youtube") {
      return (
        <img
          src={IconYoutube}
          alt="youtube"
          className="pr-2 h-10 w-10"
          onError={(e) => imageError(e)}
        />
      );
    } else if (name.toLowerCase() === "tiktok") {
      return (
        <img
          src={Tiktok}
          alt="tiktok"
          className="pr-2 h-10 w-10"
          onError={(e) => imageError(e)}
        />
      );
    }
  } else {
    return (
      <img
        src={IconYoutube}
        alt="youtube"
        className="pr-2 h-10 w-10"
        onError={(e) => imageError(e)}
      />
    );
  }
}
