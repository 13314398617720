/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";

import { SwiperSlide, Swiper } from "swiper/react";
import { Swiper as SwiperType } from "swiper";

import RelatedCampaign from "./relatedCampaign";
import DetailRight from "./detailRight";
import TermsAndConditions from "./termsAndConditions";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getDetailCampaign,
  postJoinCampaign,
  putAcceptOffer,
  putDeclineOffer,
} from "../../redux/campaign/action";
import environments from "../../environments";
import Cookies from "js-cookie";
import ImagePlaceholder from "../loading/imagePlaceholder";
import { PROFILE_DASHBOARD, PROFILE_JOB } from "../../routes/listRoute";
import { getFileExt } from "../../helpers";
import { imageError } from "../../helpers/brokenImg";
import { toast } from "react-toastify";

const IMAGE_EXT = ["jpg", "jpeg", "png", "image/jpg", "image/jpeg", "image/png"];

export default function DetailCampaign() {
  const navigate = useNavigate();
  const swiperRef = useRef<SwiperType>();
  const [isBeginning, setisBeginning] = useState<boolean>(
    swiperRef.current?.isBeginning ?? true
  );
  const token = Cookies.get("tokenY");
  const dispatch = useAppDispatch();
  const details = useAppSelector((state) => state.campaign.detailCampaign);
  const dataProfile = useAppSelector((state) => state.profileReducer.users);
  const [isEnd, setisEnd] = useState<boolean>(swiperRef.current?.isEnd ?? true);
  const [isTnC, setIsTnC] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [binusId, setBinusId] = useState<string>("");
  let { id } = useParams();

  const isAbleToJoin = useMemo(() => {
    const campaignPlatform = details?.[0]?.campaignPlatform?.map(
      (o: any) => o.platformName
    );
    const userSosmed = dataProfile?.[0]?.sosmed?.map(
      (o: any) => o.platformName
    );

    return userSosmed?.some((str: string) => campaignPlatform?.includes(str));
  }, [dataProfile, details]);

  useEffect(() => {
    if (id) {
      dispatch(
        getDetailCampaign({
          path: environments.api.campaign,
          token: token ? atob(token) : "",
          id: id,
          callBack: () => {
            window.scrollTo(0, 0);
          },
        })
      );
    }
  }, [id, isTnC]);

  const handleSlideChange = (e: SwiperType) => {
    setisBeginning(e.isBeginning);
    setisEnd(e.isEnd);
  };

  const handleSubmitJoin = () => {
    if (isAbleToJoin) {
      setIsTnC(!isTnC);
      setIsLoading(true);
      dispatch(
        postJoinCampaign({
          path: environments.api.campaign,
          token: token ? atob(token) : "",
          id: id,
          // binusId: binusId,
          callBack: (status: number) => {
            window.location.reload();
            setIsLoading(false);
          },
        })
      );
    } else {
      toast.error(
        "Unable to join. Please add social media accounts according to the campaign."
      );
    }
  };

  const handleAcceptOffer = () => {
    if (isAbleToJoin) {
      setIsLoading(true);
      dispatch(
        putAcceptOffer({
          path: environments.api.campaign,
          token: token ? atob(token) : "",
          id: id,
          userId: dataProfile[0].id,
          callBack: (status: number) => {
            setIsLoading(false);
            if (status === 200) {
              Cookies.set("jobStatus", "On Going");
              setTimeout(() => {
                navigate(PROFILE_JOB);
              }, 100);
            }
          },
        })
      );
    } else {
      toast.error(
        "Unable to join. Please add social media accounts according to the campaign."
      );
    }
  };

  const handleDeclineOffer = () => {
    setIsLoading(true);
    dispatch(
      putDeclineOffer({
        path: environments.api.campaign,
        token: token ? atob(token) : "",
        id: id,
        userId: dataProfile[0].id,
        callBack: (status: number) => {
          setIsLoading(false);
          if (status === 200) {
            navigate(PROFILE_DASHBOARD);
          }
        },
      })
    );
  };

  return (
    <>
      {details?.length > 0 ? (
        <Fragment>
          {details.map((value: any, index: number) => {
            return (
              <Fragment key={value.id}>
                <div className="flex justify-between px-5">
                  <div className="text-[#868686] text-sm">
                    <span>{value.isInvited ? "Offer" : "Campaign"}</span>
                    <span className="mx-2">{">"}</span>
                    <span className="text-[#0097DA]">Detail Campaign</span>
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 p-5">
                  <div className="md:col-span-2">
                    <div className="mb-4">
                      <div className="relative block bg-white border border-gray-200 rounded-2xl shadow-md overflow-hidden">
                        <div
                          className="flex items-center justify-center h-auto md:h-[364px] bg-red-500 bg-cover rounded-2xl"
                        >
                          <img className='w-full h-auto md:h-[364px] object-cover' src={value.banner} />
                        </div>
                      </div>
                    </div>
                    <div className="block p-8 bg-white border border-gray-200 rounded-2xl shadow-md mb-3">
                      <div>
                        <p className="title mb-2">{value.title}</p>
                        <p className="title-sm mb-2">Brand</p>
                        <p className=" text-base font-semibold text-[#212121]">
                          {value.brandName}
                        </p>
                      </div>
                    </div>
                    <div className="block p-8 bg-white border border-gray-200 rounded-2xl shadow-md mb-3">
                      <div>
                        <p className="title-sm mb-2">Influencer Criteria</p>
                        <div
                          className="list-default"
                          dangerouslySetInnerHTML={{
                            __html: value.influencerCriteria,
                          }}
                        />
                      </div>
                    </div>
                    <div className="block p-8 bg-white border border-gray-200 rounded-2xl shadow-md mb-3">
                      <div>
                        <p className="title-sm mb-2">Content Direction</p>
                        <div
                          className="list-default mb-2"
                          dangerouslySetInnerHTML={{
                            __html: value.contentDirection,
                          }}
                        />
                        {value.campaignDo?.length > 0 ? (
                          <>
                            <p className="title-sm mb-2">Do</p>
                            <ul className="list-disc pl-4 text-[#212121] leading-7 mb-2 marker:text-[#0097DA]">
                              {value.campaignDo.map((item: any, i: number) => {
                                return <li key={i}>{item.label}</li>;
                              })}
                            </ul>
                          </>
                        ) : null}
                        {value.campaignDont?.length > 0 ? (
                          <>
                            <p className="title-sm mb-2">Don’t</p>
                            <ul className="list-disc pl-4 text-[#212121] leading-7 mb-2 marker:text-[#0097DA]">
                              {value.campaignDont.map(
                                (item: any, i: number) => {
                                  return <li key={i}>{item.label}</li>;
                                }
                              )}
                            </ul>
                          </>
                        ) : null}
                        {value.campaignSampleContent?.length > 0 ? (
                          <>
                            <p className="title-sm mb-2 pt-7">Sample Content</p>
                            <div className=" w-full h-[230px] relative">
                              <Swiper
                                slidesPerView={2}
                                onSlideChange={(e) => handleSlideChange(e)}
                                onSwiper={(e) => handleSlideChange(e)}
                                onBeforeInit={(swiper) => {
                                  swiperRef.current = swiper;
                                }}
                                breakpoints={{
                                  0: {
                                    slidesPerView: 1.1,
                                  },
                                  438: {
                                    slidesPerView: 1.2,
                                  },
                                  640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                  },
                                  768: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 50,
                                  },
                                  813: {
                                    slidesPerView: 1.8,
                                    spaceBetween: 50,
                                  },
                                  1024: {
                                    slidesPerView: 1.3,
                                    spaceBetween: 40,
                                  },
                                  1280: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 50,
                                  },
                                }}
                                className="mySwiper relative"
                              >
                                {value.campaignSampleContent?.map(
                                  (val: any, i: any) => {
                                    if (val === null) return null;
                                    const mime = new URL(val.image)?.searchParams?.get("mime");

                                    if (
                                      IMAGE_EXT.includes(
                                        getFileExt(val.image)
                                      ) || IMAGE_EXT.includes(mime || '')
                                    ) {
                                      console.log(
                                        val?.image?.replaceAll(" ", "%20")
                                      );
                                      return (
                                        <SwiperSlide key={i}>
                                          <div
                                            className={`cards p-0 overflow-hidden h-[230px] w-full lg:w-[462px]`}
                                          >
                                            <img
                                              className="w-[100%] h-[100%] object-contain bg-black"
                                              src={val?.image}
                                              alt=""
                                              onError={(e) => imageError(e)}
                                            />
                                          </div>
                                        </SwiperSlide>
                                      );
                                    }

                                    return (
                                      <SwiperSlide key={i}>
                                        <div
                                          className={`cards p-0 h-[230px] w-full lg:w-[462px] bg-cover flex items-center justify-center bg-transparent`}
                                        >
                                          {/* <iframe
                                    allowFullScreen
                                    className={`cards p-0 h-[230px] w-[262px] bg-cover flex items-center justify-center bg-transparent`}
                                    src={val.image}
                                    frameBorder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                  /> */}
                                          <video controls autoPlay={false}>
                                            <source src={val.image} />
                                          </video>
                                        </div>
                                      </SwiperSlide>
                                    );
                                  }
                                )}
                              </Swiper>
                              {value.campaignSampleContent?.length > 1 ? (
                                <>
                                  <div className=" absolute top-20 left-5 right-0 z-40">
                                    <div
                                      className={`w-[32px] h-[32px] flex justify-center items-center ${
                                        isBeginning
                                          ? " bg-gray-400"
                                          : "bg-[#0097DA] cursor-pointer"
                                      } rounded-lg`}
                                      onClick={() =>
                                        swiperRef.current?.slidePrev()
                                      }
                                    >
                                      <IoChevronBackOutline className="text-white" />
                                    </div>
                                  </div>
                                  <div className=" absolute top-20 left-50 right-5 z-40">
                                    <div
                                      className={`w-[32px] h-[32px] flex justify-center items-center bg-[#0097DA] ${
                                        isEnd
                                          ? " bg-gray-400"
                                          : "bg-[#0097DA] cursor-pointer"
                                      } rounded-lg`}
                                      onClick={() =>
                                        swiperRef.current?.slideNext()
                                      }
                                    >
                                      <IoChevronForward className="text-white" />
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                        <div className="pt-7">
                          <p className="title-sm">Required Tag</p>
                          <p className="pt-3.5 text-base text-[#212121]">
                            {value.tag}
                          </p>
                        </div>
                        <div className="pt-7">
                          <p className="title-sm">Required Hashtag</p>
                          <p className="pt-3.5 text-base text-[#212121]">
                            {value.hastag}
                          </p>
                        </div>
                        <div className="pt-7">
                          <p className="title-sm">Required Web Link</p>
                          <p className="pt-3.5 text-base text-[#212121] break-words">
                            {value.linkWeb}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <DetailRight
                    isLoading={isLoading}
                    isOpen={() => setIsTnC(true)}
                    data={value}
                    handleAcceptOffer={handleAcceptOffer}
                    handleDeclineOffer={handleDeclineOffer}
                  />
                </div>
                {!value.isInvited ? <RelatedCampaign data={value} /> : null}
                {isTnC ? (
                  <TermsAndConditions
                    isOpen={isTnC}
                    closeModal={() => setIsTnC(!isTnC)}
                    binusId={value.id}
                    handleSubmitJoin={handleSubmitJoin}
                  />
                ) : null}
              </Fragment>
            );
          })}
        </Fragment>
      ) : (
        <ImagePlaceholder />
      )}
    </>
  );
}
