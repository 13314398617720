import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper";

import IconFollowers from "../../assets/svg/followers.svg";
import IconLike from "../../assets/svg/icon-like.svg";
import Buttons from "../../components/buttons";
import { useNavigate } from "react-router-dom";
import { OUR_COMMUNITY } from "../../routes/listRoute";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { useEffect } from "react";
import { OurCommunityCommand } from "../../redux/our-community/reducers";
import { OurCommunityDataModel } from "../../redux/our-community/types";

import PlatformIconYoutube from "../../assets/svg/youtube.svg";
import PlatformIconTiktok from "../../assets/svg/tiktok.svg";
import PlatformIconFacebook from "../../assets/svg/facebook.svg";
import PlatformIconLinkedin from "../../assets/svg/akar-icons_linkedin-box-fill.svg";
import PlatformIconTwitter from "../../assets/svg/twitter.svg";
import PlatformIconInstagram from "../../assets/svg/instagram.svg";
import testi1 from '../../assets/Testimoni/erent.jpg';
import testi2 from '../../assets/Testimoni/zahra.webp';
import testi3 from '../../assets/Testimoni/wulan.jpeg';

export const PLATFORM_ICON: Record<string, string> = {
  "instagram": PlatformIconInstagram,
  "youtube": PlatformIconYoutube,
  "tiktok": PlatformIconTiktok,
  "facebook": PlatformIconFacebook,
  "linkedin": PlatformIconLinkedin,
  "twitter": PlatformIconTwitter
}

const DUMMY_IG_INFLUENCERS: OurCommunityDataModel[] = [
  {
    "platformId": '',
    "platformName": 'Instagram',
    "avatar": testi3,
    "city": null,
    "fullname": 'Wulan Purnamasari',
    username: '@wulanpurnamaasari',
    "followers": '1,4k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Instagram',
    "avatar": testi1,
    "city": null,
    "fullname": 'Erent Jenny',
    username: '@its.renxx',
    followers: '81,2k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Instagram',
    "avatar": testi2,
    "city": null,
    "fullname": 'Zahra Putri',
    username: '@zahraputrisurya',
    followers: '535',
    "engagementRate": ''
  },
];

export default function SectionFour() {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.ourCommunity?.list);
  const navigate = useNavigate();

  const pagination = {
    el: "#pagination-custome",
    clickable: true,
    renderBullet: function (_: any, className: any) {
      return '<span class="' + className + '">' + "" + "</span>";
    },
  };

  const handleButton = () => {
    navigate(OUR_COMMUNITY);
  };

  useEffect(() => {
    dispatch(OurCommunityCommand.getContent({}));
  }, []);

  return (
    <div className="min-h-[759px] bg-[url('/src/assets/BInus/home/4-1.svg')] bg-auto bg-no-repeat bg-center lg:bg-cover">
      <div className="pt-8 md:pt-16">
        <div className="text-[36px] font-bold lg:text-[44px] text-white text-center px-4">Meet Our Influencers</div>
        <div className="max-w-6xl mx-auto py-10 md:pt-20 md:pb-16 px-4 xl:px-0">
          <Swiper
            slidesPerView={4}
            breakpoints={{
              0: {
                slidesPerView: 1.1,
              },
              300: {
                slidesPerView: 1.2,
                spaceBetween: 10,
              },
              556: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            pagination={pagination}
            modules={[Pagination]}
          >
            {DUMMY_IG_INFLUENCERS?.map((x: OurCommunityDataModel) => {
              return (
                <SwiperSlide key={`influencer-${x}`}>
                  <div>
                    <div className="cards shadow-none border-0 w-[264px]">
                      <div className="flex justify-end">
                        <div>
                          <img src={PLATFORM_ICON[x.platformName.toLowerCase()]} alt="icon" />
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <img
                          className="w-[120px] h-[120px] rounded-full object-cover"
                          src={x.avatar || ''}
                          alt="Rounded avatar"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src="https://cdn-icons-png.flaticon.com/512/147/147142.png";
                          }}
                        />
                      </div>
                      <div className="my-4 text-center">
                        <p className="title-sm text-[#343536] py-2 font-bold">
                          {x.fullname}
                        </p>
                        {x.username ? <p className="title-sm">{x.username}</p> : null}
                        {x.city ? <p className="title-sm">{x.city}</p> : null}
                        <div className="flex items-center justify-center text-center pt-4">
                          <span className="pr-2">
                            <img src={IconFollowers} alt="followrs" />
                          </span>
                          <p className="text-[#343536] font-medium text-sm">
                            {x.followers} <span className="title-sm">Subscribers</span>
                          </p>
                        </div>
                        {x.engagementRate ? <div className="flex items-center justify-center text-center my-2">
                          <span className="pr-2">
                            <img src={IconLike} alt="followrs" />
                          </span>
                          <p className="text-[#343536] font-medium text-sm">
                            {x.engagementRate}{" "}
                            <span className="title-sm">Engagement Rate</span>
                          </p>
                        </div> : null}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="flex justify-center mt-20" id="pagination-custome" />
          <div className="flex justify-center py-10">
            <Buttons
              label="Show All"
              className="text-white bg-transparent border border-[#F7FCFE] w-[169px] hover:bg-transparent"
              onClick={handleButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
