/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import Buttons from "../../components/buttons";
import ImgPeople from "../../assets/BInus/home/2.svg";
import IconPartner from "../../assets/BInus/home/partnership-handshake 1.svg";
import IconInsight from "../../assets/BInus/home/insight.svg";
import IconBadget from "../../assets/BInus/home/badge.svg";
import IconAtm from "../../assets/BInus/home/atm.svg";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AUTH_REGISTER } from "../../routes/listRoute";

export const listCategory = [
  {
    name: "Student",
  },
  {
    name: "Professional / Expert",
  },
  {
    name: "Alumnae",
  },
  {
    name: "Academics",
  },
  {
    name: "Employee",
  },
  {
    name: "Journalist",
  },
];

export const listBenefits = [
  {
    title: "Make More Money from Brand Collaborations",
    subtitle:
      "Dapatkan passive income melalui partisipasi campaign di BINUSFLUENCER.",
    icon: IconPartner,
  },
  {
    title: "Get Point Reward & SAT Point",
    subtitle:
      "Kumpulkan reward dan SAT Point pada setiap campaign.",
    icon: IconBadget,
  },
  {
    title: "Easy and Fast Balance Withdrawal",
    subtitle:
      "Penarikan saldo mudah dan lebih cepat.",
    icon: IconAtm,
  },
  {
    title: "Easy to Provide Insight from Publish Content",
    subtitle:
      "Report terintegrasi dengan tautan konten yang sudah di publish di media sosial.",
    icon: IconInsight,
  },
];

export default function SectionTwo() {
  const navigate = useNavigate();
  let tokenBase64 = Cookies.get("tokenY");
  const handleButton = () => {
    if (!tokenBase64) {
      navigate(AUTH_REGISTER);
    }
  };

  return (
    <div className="bg-[url('/src/assets/BInus/home/3.svg')] bg-cover">
      <div className="max-w-6xl mx-auto py-5 px-6 xl:px-0">
        <div className="flex items-center gap-5">
          <div className="w-full lg:w-[660px]">
            <p className="text-[36px] font-bold lg:text-[44px] mb-4">
              Siapa saja yang bisa menjadi bagian dari{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
                BINUSFLUENCER
              </span>
              ?
            </p>
            <p className="title-sm text-[#212121]">
              Buat kamu, BINUSIAN, khususnya <strong>mahasiswa</strong> dan <strong>alumni</strong> yang aktif di dunia media sosial bisa menjadi bagian BINUSFLUENCER.
            </p>
            {/* <div className="w-full max-w-[390px] my-5">
              <div className="flex flex-wrap gap-3">
                {listCategory.map((val, i) => {
                  return (
                    <div className="w-[166px]" key={i}>
                      <ul className="list-disc pl-4 marker:text-[#0097DA] ring-offset-4 ring-offset-slate-50  text-[#212121] text-sm">
                        <li>{val.name}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div> */}
            {!tokenBase64 ? (
              <div className="mb-8 md:mb-0 mt-10 flex flex-wrap items-center gap-5">
                <Buttons
                  label="Register Now"
                  className="w-[169px]"
                  onClick={handleButton}
                />
              </div>
            ) : null}
          </div>
          <div className="pb-20 hidden lg:block">
            <img src={ImgPeople} alt="image people" className="" />
          </div>
        </div>
        <section className=" sm:mt-10 lg:mt-20 lg:mb-36">
          <div className="flex justify-center mb-16">
            <p className="text-[36px] font-bold lg:text-[44px]">
              The{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
                Benefits
              </span>
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            {listBenefits.map((val, i) => {
              return (
                <div className="flex flex-wrap md:items-center gap-3" key={i}>
                  <div className="w-[64px] h-[64px] bg-gradient-to-r from-[#0097DA] to-[#174479] flex items-center justify-center rounded-full">
                    <img src={val.icon} alt="icon" />
                  </div>
                  <div className="w-[460px]">
                    <p className="text-base font-bold text-[#343536] pb-3">
                      {val.title}
                    </p>
                    <p className="title-sm text-[#212121]">{val.subtitle}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </div>
  );
}
