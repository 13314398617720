import environments from "../../environments";
import fetchAxios from "../../helpers/fetchAxios";
import { OurCommunityAction, OurCommunityActionType, OurCommunityModel } from "./types";

const initialState: OurCommunityModel = {
  list: [],
};

const OurCommunityReducer = (
  state: OurCommunityModel = initialState,
  action: OurCommunityAction
) => {
  switch (action.type) {
    case OurCommunityActionType.GET_LIST_COMMUNITY:
      return { ...state, list: action.payload };

    default:
      return { ...state };
  }
};

const OurCommunityCommand = {
  getContent: ({ platform, take }: { platform?: string, take?: number }) => {
    return (dispatch: any) => {
      fetchAxios({
        url: `${environments.api.ourCommunity}?${platform ? `platform=${platform}&` : ''}Take=${take || 8}`,
        method: "get",
      }).then((response) => {
          const res = response.data?.data?.rows;
          dispatch({
            type: OurCommunityActionType.GET_LIST_COMMUNITY,
            payload: res,
          });
      });
    };
  },
};

export { OurCommunityReducer, OurCommunityCommand };
