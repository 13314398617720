/* eslint-disable import/no-anonymous-default-export */
export const env = process.env.REACT_APP_BE_URL

interface EnvironmentsModel {
  api: Record<string, string>
}

const environments: EnvironmentsModel = {
  api: {
    signinBinusFluencer: `${env}/Binusfluencer/login`,
    signinBinusBrand: `${env}/Brand/login`,
    register: `${env}/Register`,
    validateNim: `${env}/register/validate-nim`,
    campaign: `${env}/campaign`,
    earning: `${env}/earning`,
    targetAudience: `${env}/TargetAudienceRegisterList`,
    upload: `${env}/misc/uploadFile`,
    misc: `${env}/misc`,
    listCampaign: `${env}/listCampaign`,
    termofuse: `${env}/termofuse`,
    privacypolicy: `${env}/privacypolicy`,
    account: `${env}/Account`,
    bankAccount: `${env}/BankAccount`,
    linkedin: `${env}/LinkedIn`,
    facebook: `${env}/facebook`,
    instagram: `${env}/facebook`,
    youtube: `${env}/youtube`,
    twitter: `${env}/twitter`,
    tiktok: `${env}/tiktok`,
    me: `${env}/me`,
    job: `${env}/job`,
    forgotFluencer: `${env}/Binusfluencer`,
    addedSocialMedia: `${env}/SocialMediaAccount`,
    listFAQ: `${env}/ListFAQ`,
    notification: `${env}/notification`,
    ourCommunity: `${env}/OurCommunity`,
    ktpNpwp: `${env}/binusfluencer`
  },
}

export default environments
