import React from "react";

interface TDot {
  className?: string;
}

export default function Dot({ className }: TDot) {
  return (
    <div
      className={`h-[10px] w-[10px] rounded-full bg-gradient-to-r from-[#0097DA] to-[#174479] ring-offset-2 ring-2 ring-[#0097DA] ${className}`}
    />
  );
}
