export interface NotificationDataModel {
  createdAt: string
  wording: {
    title: string | null;
    body: string | null;
  };
  data: {
    JobId: string | null;
    campaignName: string | null;
  } | null;
  id: string;
  type: string;
  notifiableType: string;
  notifiableId: string;
  readAt: string | null;
}

export interface NotificationModel {
    list: NotificationDataModel[]
}

export enum NotificationActionType {
    GET_LIST_NOTIFICATIONS = "GET_LIST_NOTIFICATIONS"
};

export type NotificationAction = {
    payload: NotificationDataModel[],
    type: NotificationActionType.GET_LIST_NOTIFICATIONS
}
