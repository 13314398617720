import { Fragment, useRef, useState } from "react";
import { SwiperSlide, Swiper } from "swiper/react";
import { IoChevronBackOutline, IoChevronForward } from "react-icons/io5";
import { Swiper as SwiperType } from "swiper";

import IconIg from "../../assets/svg/ig_colors.svg";
import Facebook from "../../assets/svg/facebook.svg";
import Twitter from "../../assets/svg/twitter.svg";
import Linkedin from "../../assets/svg/akar-icons_linkedin-box-fill.svg";
import Youtube from "../../assets/svg/youtube-circle.svg";
import dayjs from "dayjs";
import { formatRp } from "../../helpers";
import { NavLink } from "react-router-dom";
import { CAMPAIGN } from "../../routes/listRoute";
import platForm from "../../components/platForm/platForm";

export default function RelatedCampaign(data: any) {
  const swiperRef = useRef<SwiperType>();
  const [isBeginning, setisBeginning] = useState<boolean>(
    swiperRef.current?.isBeginning ?? true
  );
  const [isEnd, setisEnd] = useState<boolean>(swiperRef.current?.isEnd ?? true);

  const handleSlideChange = (e: SwiperType) => {
    setisBeginning(e.isBeginning);
    setisEnd(e.isEnd);
  };

  return (
    <Fragment>
      <div className="p-5 w-full">
        <div className="flex justify-between mb-4 px-4 mt-7 md:px-0 md:my-5">
          <div>
            <p className="text-[#343536] text-xl font-semibold">
              Related Campaign
            </p>
          </div>
          <div className="flex gap-3">
            <div
              className={`w-[32px] h-[32px] flex justify-center items-center ${
                isBeginning ? " bg-gray-400" : "bg-[#0097DA] cursor-pointer"
              } rounded-lg`}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <IoChevronBackOutline className="text-white" />
            </div>
            <div
              className={`w-[32px] h-[32px] flex justify-center items-center bg-[#0097DA] ${
                isEnd ? " bg-gray-400" : "bg-[#0097DA] cursor-pointer"
              } rounded-lg`}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <IoChevronForward className="text-white" />
            </div>
          </div>
        </div>
        <div>
          <Swiper
            slidesPerView={2}
            onSlideChange={(e) => handleSlideChange(e)}
            onSwiper={(e) => handleSlideChange(e)}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 2,
                spaceBetween: 5,
              },
              640: {
                slidesPerView: 2.2,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 10,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 5,
              },
            }}
            className="mySwiper relative"
          >
            {data.data?.relatedCampaign?.map((val: any) => {
              const encodedUrl = encodeURI(val.banner);
              
              return (
                <SwiperSlide>
                  <NavLink
                    to={`${CAMPAIGN}/${val.id}`}
                    className="cards p-0 w-full sm:w-[271px] lg:w-[271px] rounded-lg overflow-hidden cursor-pointer"
                  >
                  <div
                    className="relative h-[194px] bg-red-500 flex justify-center items-center text-center px-6 bg-cover"
                  >
                    <img className='absolute left-0 top-0 w-full h-[194px] object-cover' src={encodedUrl} />
                    <div className="z-10">
                      <p className="text-2xl text-white font-bold line-clamp-3">
                        {val.title}
                      </p>
                      <p className="font-normal text-[10px] opacity-70 text-[#FFFFFF]">
                        {val.to}
                      </p>
                    </div>
                  </div>
                    <div className="px-4 my-5">
                      <p className="title-sm py-2">{val.brandName}</p>
                      <div className="h-20">
                        <div
                          className="line-clamp-3 rel-camp-text"
                          dangerouslySetInnerHTML={{
                            __html: val.contentDirection,
                          }}
                        />
                      </div>
                      <p className="title-sm py-2">Deadline Post</p>
                      <p className="text-[#2F3335] text-base py-2 font-semibold">
                        {dayjs(val.endCampaign).format("DD MMM YYYY")}
                      </p>
                      <hr className="border-dashed border-[#D5D5D5] py-2" />
                      <div className="flex flex-wrap gap-1 justify-center items-center">
                        {val.platform.map((platform: any) => {
                          return platForm(platform.name);
                        })}
                        <div className="px-1">
                          <p className="title-sm">Start From</p>
                        </div>
                        <div>
                          <p className="text-[#0097DA] font-base font-semibold">
                            {`Rp. ${formatRp(val.startPrice)}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </Fragment>
  );
}
