import IconYoutube from "../assets/svg/logos_youtube-icon.svg";
import IconFacebook from "../assets/svg/logos_facebook.svg";
import IconTiktok from "../assets/svg/logos_tiktok-icon.svg";
import IconLinkedIn from "../assets/svg/akar-icons_linkedin-box-fill.svg";
import IconInstagram from "../assets/svg/instagram.svg";
import IconTwitter from "../assets/svg/twitter.svg";
import IconAdvan from "../assets/BInus/brands/company/advan-330x160.svg";
import IconKaleyo from "../assets/BInus/brands/company/bebek-kaleyo-330x160.svg";
import IconCimory from "../assets/BInus/brands/company/cimory-330x160.svg";
import IconKolo from "../assets/BInus/brands/company/kolo-330x160.svg";
import IconLimu from "../assets/BInus/brands/company/limu-330x160.svg";
import IconManzone from "../assets/BInus/brands/company/manzone-330x160.svg";
import IconSocial from "../assets/BInus/brands/company/socialights-330x160.svg";
import IconTraveloka from "../assets/BInus/brands/company/travelok-330x160.svg";
import IconTrueID from "../assets/BInus/brands/company/true-id-330x160.svg";
import IconSmartfren from "../assets/BInus/brands/company/smartfren-330x160.svg";

import { RiSettingsFill } from "react-icons/ri";

import { MdSpaceDashboard } from "react-icons/md";

import {
  CAMPAIGN,
  FAQ,
  FORBINUSFLUENCER,
  FOR_BRAND,
  HOME,
  HOW_TO_REGISTER,
  OUR_COMMUNITY,
  PRIVACYPOLICY,
  TANDC,
} from "../routes/listRoute";
import { IoCopy, IoPeople } from "react-icons/io5";

import testi1 from "../assets/Testimoni/erent.jpg";
import testi2 from "../assets/Testimoni/zahra.webp";
import testi3 from "../assets/Testimoni/wulan.jpeg";
import { OurCommunityDataModel } from "../redux/our-community/types";

export const listCatagoryBinusFluencer = [
  {
    name: "Actress / Actor",
  },
  {
    name: "Art / Design",
  },
  {
    name: "Beauty",
  },
  {
    name: "Block Lovers",
  },
  {
    name: "Bussiness",
  },
  {
    name: "Cinema",
  },
  {
    name: "Entrepreneur",
  },
  {
    name: "Entertainment",
  },
  {
    name: "Fashion",
  },
  {
    name: "Food",
  },
  {
    name: "Gaming",
  },
  {
    name: "Health",
  },
  {
    name: "Home / Garden",
  },
  {
    name: "Journalist / Media",
  },
  {
    name: "Lifestyle",
  },
  {
    name: "Medicine",
  },
  {
    name: "Model",
  },
  {
    name: "Music",
  },
  {
    name: "Parents / Kids",
  },
  {
    name: "Photography",
  },
  {
    name: "Science",
  },
  {
    name: "Shopping",
  },
  {
    name: "Sport",
  },
  {
    name: "Trave",
  },
];

// const showSocmed = () => {
//   let shouldShow = false;
//   const currentBase = window.location.hostname;
//   const ALLOWED_URL = ["localhost", "binusfluencer.demoapp.xyz"];

//   if (ALLOWED_URL.includes(currentBase)) {
//     shouldShow = true;
//   }

//   return shouldShow;
// };

export const listSocialMedia: {
  name: string;
  icon: string;
  active: boolean;
  disabled?: boolean;
}[] = [
  {
    name: "youtube",
    icon: IconYoutube,
    active: true,
  },
  {
    name: "facebook",
    icon: IconFacebook,
    active: true,
  },
  {
    name: "tiktok",
    icon: IconTiktok,
    active: true,
    // active: showSocmed(),
  },
  {
    name: "linkedin",
    icon: IconLinkedIn,
    active: true,
  },
  {
    name: "instagram",
    icon: IconInstagram,
    active: true,
  },
  {
    name: "twitter",
    icon: IconTwitter,
    active: true,
  },
];

export const listTermCondition = [
  {
    desc: "Anggota dari BINUSFLUENCER adalah mahasiswa, alumni, dosen ataupun karyawan dari BINUS Group.",
  },
  {
    desc: "BINUSFLUENCER tidak boleh merangkap sebagai EC atau TP aktif.",
  },
  {
    desc: "BINUSFLUENCER wajib memiliki social media Pribadi seperti Facebook, Instagram, Tiktok, Twitter maupun Youtube.",
  },
  {
    desc: "BINUSFLUENCER terbagi atas 4 kategori yaitu : Nano (500-10.000 Followers), Micro (10.000-100.000 Followers), Macro (100.000-500.000 Followers) dan Mega Influencer (500.000 - 1.000.000 Followers ).",
  },
  {
    desc: "Setiap BINUSFLUENCER wajib untuk menjalankan seluruh campaign yang dimiliki BINUS Group dan diposting dalam social media masing-masing dan tidak boleh di private.",
  },
  {
    desc: "BINUSFLUENCER akan mendapatkan benefit berupa kompensasi sesuai dengan tingkat kategori BINUSFLUENCER.",
  },
  {
    desc: "Dilarang untuk menjiplak atau plagiat konten yang telah dibuat untuk menjalankan Campaign, kecuali satu komunikasi dari pihak BINUS.",
  },
  {
    desc: "Semua job yang akan diberikan akan melalui Platform BINUSFLUENCER.",
  },
  {
    desc: "Tidak diizinkan bergabung dengan BINUSFLUENCER apabila pernah membuat content tentang Minuman alkohol, Pornografi, Sara & Politik.",
  },
  {
    desc: "Kompensasi dapat diberikan apabila Influencer telah menyelesaikan posting yang telah di tentukan oleh BINUS Group.",
  },
];

export const kebijakanPrivasiURL = "https://binus.ac.id/privacy-policy";

export const listMenu = (token: string) => [
  {
    label: "Home",
    link: HOME,
    active: false,
    isHidden: false,
  },
  {
    label: "For Binusfluencer",
    link: FORBINUSFLUENCER,
    active: false,
    isHidden: false,
  },
  // {
  //   label: "For Brands",
  //   link: FOR_BRAND,
  //   active: false,
  //   isHidden: false,
  // },
  {
    label: "Campaign",
    link: CAMPAIGN,
    active: false,
    isHidden: token ? false : true,
    // isHidden: false,
  },
  {
    label: "Our Community",
    link: OUR_COMMUNITY,
    active: false,
    isHidden: false,
  },
];

export const listMenuFooter = [
  {
    label: "Home",
    link: HOME,
    active: false,
  },
  {
    label: "For Binusfluencer",
    link: FORBINUSFLUENCER,
    active: false,
  },
  // {
  //   label: "For Brands",
  //   link: FOR_BRAND,
  //   active: false,
  // },
  {
    label: "Campaign",
    link: CAMPAIGN,
    active: false,
  },
  {
    label: "Our Community",
    link: OUR_COMMUNITY,
    active: false,
  },
  {
    label: "FAQ",
    link: FAQ,
    active: false,
  },
];

export const listMoreFooter = [
  {
    label: "How to Join Us",
    link: HOW_TO_REGISTER,
  },
  {
    label: "Terms and Conditions",
    link: TANDC,
  },
  {
    label: "Privacy Policy",
    link: PRIVACYPOLICY,
  },
];

export const listMenuProfile = [
  {
    label: "Dashboard",
    // icons: IconDashboard,
    // activeIcons: IconDashboardActive,
    icons: <MdSpaceDashboard className="h-[24px] w-[24px]" />,
    path: "dashboard",
    active: true,
  },
  {
    label: "Job",
    // icons: IconJob,
    // activeIcons: IconJobActive,
    icons: <IoCopy className=" h-[24px] w-[24px]" />,
    path: "job",
    active: false,
  },
  {
    label: "Social Media",
    // icons: IconSocialMedia,
    // activeIcons: IconSocialMediaActive,
    icons: <IoPeople className=" h-[24px] w-[24px]" />,
    path: "social-media",
    active: false,
  },
  {
    label: "Account Settings",
    // icons: IconAccountSetting,
    // activeIcons: IconAccountSettingActive,
    icons: <RiSettingsFill className=" h-[24px] w-[24px]" />,
    path: "account-setting",
    active: false,
  },
];

export enum Steps {
  DASHBOARD = "dashboard",
  JOB = "job",
  SOCIAL_MEDIA = "social-media",
  ACCOUNT_SETTING = "account-setting",
  SIGNOUT = "signout",
}

export const listCommpany = [
  {
    icon: IconAdvan,
  },
  {
    icon: IconKaleyo,
  },
  {
    icon: IconCimory,
  },
  {
    icon: IconKolo,
  },
  {
    icon: IconLimu,
  },
  {
    icon: IconManzone,
  },
  {
    icon: IconSocial,
  },
  {
    icon: IconTraveloka,
  },
  {
    icon: IconTrueID,
  },
  {
    icon: IconSmartfren,
  },
];

export const optionReason = [
  {
    value: "Have created another account",
    label: "Have created another account",
  },
  {
    value: "Problems getting started",
    label: "Problems getting started",
  },
  {
    value: "Just want to rest",
    label: "Just want to rest",
  },
  {
    value: "Too busy/too distracting",
    label: "Too busy/too distracting",
  },
  {
    value: "Want to delete something",
    label: "Want to delete something",
  },
  {
    value: "Can't find campaign to register",
    label: "Can't find campaign to register",
  },
  {
    value: "Worried about my data",
    label: "Worried about my data",
  },
  {
    value: "Something else",
    label: "Something else",
  },
];

export const listStatus = [
  "Silahkan upload draft konten Anda terlebih dahulu untuk direview",
  "Draft konten Anda sedang direview mohon tunggu",
  "Draft konten anda mendapat feedback",
  "Draft konten Anda disetujui silahkan Upload konten Anda",
  "Content Anda sedang direview mohon tunggu",
  "Content Anda mendapat feedback",
  "Menunggu konfirmasi penyelesaian admin",
  "Please wait 7 working days to make a withdrawal",
];

export const PERSONAL_DETAILS = "Personal Details";
export const SECURITY = "Security";
export const BANK_ACCOUNT = "Bank Account";
export const FORM_KTP_NPWP = "KTP & NPWP";

export const ALL = "All";
export const FACEBOOK = "Facebook";
export const INSTAGRAN = "Instagram";
export const TWITTER = "Twitter";
export const LINKEDIN = "Linkedin";

export const BINUS_SOCMED_URLS = {
  facebook: "https://www.facebook.com/universitasbinanusantara/",
  instagram: "https://www.instagram.com/binusfluencer/",
  linkedin: "https://id.linkedin.com/school/universitas-bina-nusantara",
};

export const LANDING_TESTIMONIES = [
  {
    img: testi3,
    name: "Wulan Purnamasari",
    username: "@wulanpurnamaasari",
    followers: "1,4k",
    testimony:
      "Alasan mau bergabung di BINUSINFLUENCER, karena saya ingin memamfaatkan sosial media dengan sesuatu yang lebih berarti, daripada hanya scroll hal-hal yang membuang waktu, sadar sosial media dimasa sekarang cukup besar dampaknya ke pengguna, maka saya ingin jadi salah satu orang yang bisa sharing hal baik dan lebih berguna ke orang lain. Bergabung di BINUSINFLUENCER juga dengan harapan dapat membantu saya membangun relasi ke teman-teman BINUSIAN lainnya yang mana saya ini belajar online dan kurang berinteraksi dengan mahasiswa lainnya.",
  },
  {
    img: testi1,
    name: "Erent Jenny",
    username: "@its.renxx",
    followers: "81,2k",
    testimony:
      "Alasan ingin bergabung dengan BINUSFLUENCER karena saya ingin mendapatkan banyak pengalaman dengan bergabung menjadi bagian dari komunitas BINUSFLUENCER, saya ingin menambah relasi/networking, dan saya ingin menginspirasi banyak orang karena dengan bergabung di BINUSFLUENCER saya akan semakin terdorong untuk mengupgrade diri menjadi lebih baik lagi dari sebelumnya.",
  },
  {
    img: testi2,
    name: "Zahra Putri",
    username: "@zahraputrisurya",
    followers: "535",
    testimony:
      "Karena bisa menambah relasi, mendapat benefit yang banyak serta menambah pengalaman saya",
  },
];
