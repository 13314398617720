import Cookies from "js-cookie";
import React, { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import environments from "../../environments";
import { useAppDispatch } from "../../hooks/hooks";
import { getUsers } from "../../redux/profile/action";
import {
  FAQ,
  FORBINUSFLUENCER,
  FOR_BRAND,
  HOME,
  HOW_TO_REGISTER,
  OUR_COMMUNITY,
  PRIVACYPOLICY,
  TANDC,
} from "../../routes/listRoute";

import Footer from "./footer";
import NavBar from "./navbar";
import { NotificationCommand } from "../../redux/notifications/reducers";

let interval: any = null;
const TIMER = 3 * 60 * 1000;

export default function Layout(props: any) {
  const { children } = props;
  let stylesCss: string = "";
  let location = useLocation();
  let dispatch = useAppDispatch();
  const token = Cookies.get("tokenY");

  const fetchProfile = useCallback(() => {
    dispatch(
      getUsers({
        path: environments.api.me,
        token: token ? atob(token) : "",
      })
    );
  }, [token]);

  const fetchNotifications = useCallback(() => {
    dispatch(
      NotificationCommand.getNotifications({
        token: token ? atob(token) : ""
      })
    );
  }, [token]);

  const startInterval = () => {
    if (!interval) {
      interval = setInterval(fetchNotifications, TIMER)
    }
  };

  useEffect(() => {
    if (token) {
      fetchProfile();
      fetchNotifications();
      startInterval();
    }

    return () => {
      clearInterval(interval);
      interval = null;
    }
  }, []);

  switch (location.pathname) {
    case OUR_COMMUNITY:
      stylesCss = "p-0 max-w-none";
      break;
    case TANDC:
      stylesCss = "p-0 max-w-none";
      break;
    case HOW_TO_REGISTER:
      stylesCss = "p-0 max-w-none";
      break;
    case FAQ:
      stylesCss = "p-0 max-w-none";
      break;
    case PRIVACYPOLICY:
      stylesCss = "p-0 max-w-none";
      break;
    case HOME:
      stylesCss = "p-0 max-w-none";
      break;
    case FOR_BRAND:
      stylesCss = "p-0 max-w-none";
      break;
    case FORBINUSFLUENCER:
      stylesCss = "p-0 max-w-none";
      break;
    default:
      stylesCss = "pt-8 max-w-6xl";
      break;
  }

  return (
    <div className="font-inter bg-gray-100">
      <NavBar />
      <div className="min-h-screen bg-gray-100">
        <div className={`mx-auto ${stylesCss}`}>{children}</div>
      </div>
      <Footer />
    </div>
  );
}
