import {
  ACCOUNTBANK,
  DETAILJOBS,
  LISTACCOUNTBANK,
  LISTACCOUNTBANKALL,
  LISTEARNING,
  LISTJOBDRAFT,
  LISTJOBS,
  LISTMAJOR,
  LISTONGOING,
  LISTPOSTCONTENT,
  LISTSHOWPROFILE,
  USERS,
  ADDED_SOCIAL_MEDIA_ACCOUNTS,
  DETAIL_EARNING,
  DETAIL_JOB_PERFORMANCE,
  LIST_SHOW_PROFILE_SOCIAL_MEDIAS,
  GET_KTP_NPWP,
} from "./typeAction";

export interface SocialMediaDataModel {
  category: string | null;
  id: string;
  platformId: string;
  platformName: string;
  avatar: string;
  fullname: string;
  followers: string;
}

export interface JobPerformanceDataModel {
  contentId: string;
  postContentId: string;
  likes: number | null;
  comments: number | null;
  bookmarks: number | null;
  share: number | null;
  malePercentation: number | null;
  femealePercentation: number | null;
  below18: number | null;
  between1823: number | null;
  beetween2434: number | null;
  between3545: number | null;
  above45: number | null;
  esrMediaValue: number | null;
  esrReach: number | null;
}

export interface ShowProfileSocialMediasModel {
  platformId: string;
  platformName: string;
  profile: string;
  follower: string;
  accountId: string;
  fullname: string;
}

export interface KtpNpwpDataModel {
  ktpNo: string | null;
  ktpImage: string | null;
  npwpNo: string | null;
  npwpImage: string | null;
}

type TProfile = {
  addedSocialMedia: SocialMediaDataModel[];
  loading: boolean;
  loadingEarning: boolean;
  isRenderDetails?: boolean;
  datas: object;
  earningList: object;
  detailEarning: {
    earning: number | null;
    id: string;
  };
  showProfile: any;
  showProfileSocialMedias: ShowProfileSocialMediasModel[];
  listMajor: any;
  listAccountBank: any;
  allListBankAccount: any;
  listJobDraft: any;
  listPostContent: any;
  users: object[];
  detailJobs: object;
  isLoadingDetail: boolean;
  accountBank: object[];
  listOnGoing: object[];
  jobPerformace: JobPerformanceDataModel[];
  ktpNpwp: KtpNpwpDataModel | null;
};

type TAction = {
  type: string;
  payload?: any;
};

export const DEFAULT_JOB_PERFORMANCE: JobPerformanceDataModel = {
  contentId: "",
  postContentId: "",
  likes: null,
  comments: null,
  bookmarks: null,
  share: null,
  malePercentation: null,
  femealePercentation: null,
  below18: null,
  between1823: null,
  beetween2434: null,
  between3545: null,
  above45: null,
  esrMediaValue: null,
  esrReach: null,
};

const initialState: TProfile = {
  addedSocialMedia: [],
  loading: false,
  loadingEarning: true,
  isRenderDetails: false,
  datas: {},
  earningList: {},
  detailEarning: {
    earning: null,
    id: "",
  },
  showProfile: [],
  showProfileSocialMedias: [],
  listMajor: [],
  listAccountBank: [],
  allListBankAccount: [],
  listJobDraft: [],
  listPostContent: [],
  users: [],
  detailJobs: {},
  isLoadingDetail: true,
  accountBank: [],
  listOnGoing: [],
  jobPerformace: [],
  ktpNpwp: null,
};

const profileReducer = (state: TProfile = initialState, action: TAction) => {
  switch (action.type) {
    case LISTJOBS:
      return {
        ...state,
        loading: true,
        datas: action.payload,
      };
    case LISTONGOING:
      return {
        ...state,
        listOnGoing: action.payload,
      };
    case LISTEARNING:
      return {
        ...state,
        loadingEarning: false,
        earningList: action.payload,
      };
    case DETAIL_EARNING:
      return {
        ...state,
        detailEarning: action.payload,
      };
    case LISTSHOWPROFILE:
      return {
        ...state,
        showProfile: action.payload,
      };
    case LIST_SHOW_PROFILE_SOCIAL_MEDIAS:
      return {
        ...state,
        showProfileSocialMedias: action.payload,
      };
    case LISTMAJOR:
      return {
        ...state,
        listMajor: action.payload,
      };
    case LISTACCOUNTBANK:
      return {
        ...state,
        listAccountBank: action.payload,
      };
    case ACCOUNTBANK:
      return {
        ...state,
        accountBank: action.payload,
      };
    case LISTACCOUNTBANKALL:
      return {
        ...state,
        allListBankAccount: action.payload,
      };
    case LISTJOBDRAFT:
      return {
        ...state,
        listJobDraft: action.payload,
        isRenderDetails: !initialState.isRenderDetails,
      };
    case LISTPOSTCONTENT:
      return {
        ...state,
        listPostContent: action.payload,
        isRenderDetails: !initialState.isRenderDetails,
      };
    case USERS:
      return {
        ...state,
        users: action.payload,
      };
    case DETAILJOBS:
      return {
        ...state,
        isLoadingDetail: !initialState.isLoadingDetail,
        detailJobs: action.payload,
      };
    case ADDED_SOCIAL_MEDIA_ACCOUNTS:
      return {
        ...state,
        addedSocialMedia: action.payload,
      };
    case DETAIL_JOB_PERFORMANCE:
      return {
        ...state,
        jobPerformance: action.payload,
      };
    case GET_KTP_NPWP:
      return {
        ...state,
        ktpNpwp: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
