import Cookies from "js-cookie";
import React from "react";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/buttons";
import { AUTH_SIGNIN } from "../../routes/listRoute";

export default function HeadersCommunity() {
  const navigate = useNavigate();
  let tokenBase64 = Cookies.get("tokenY");
  const handleButton = () => {
    if (!tokenBase64) {
      navigate(AUTH_SIGNIN);
    }
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 pt-20 px-10 lg:px-7 xl:px-0">
      <div className="flex flex-wrap items-center">
        <div>
          <p className="uppercase font-extrabold text-[30px] lg:text-[56px] bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479]">
            binusfluencer
          </p>
          <p className=" font-extrabold text-2xl lg:text-[56px] text-[#343536]">
            Community
          </p>
        </div>
      </div>
      <div className="flex flex-wrap items-center">
        <div className="py-5">
          <p className="title-sm text-[#212121]">
            Peserta Program adalah mahasiswa/i BINUS HIGHER EDUCATION yang telah lolos seleksi yang diadakan oleh Tim BINUSFLUENCER dan memiliki akun pada Aplikasi BINUSFLUENCER sehingga dapat berpartisipasi pada Campaign.
          </p>
          {!tokenBase64 ? (
            <div className="py-5 flex flex-wrap gap-5">
              <div className="w-full lg:w-[169px]">
                <Buttons label="Join" onClick={handleButton} />
              </div>
              {/* <div className="w-full lg:w-[169px]">
              <Buttons
                label="Join Brand"
                className="bg-white border border-[#0097DA] text-[#0097DA] hover:bg-white"
              />
            </div> */}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
