import React, { Suspense } from "react"
import "./App.css"
import "react-datepicker/dist/react-datepicker.css"
import "swiper/css"
import "react-toastify/dist/ReactToastify.css"
import "swiper/css/navigation"
import store from "./redux/store"
import Routers from "./routes/routers"
import { ToastContainer } from "react-toastify"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import Loading from "./pages/loading"
// import TagManager from "react-gtm-module"

// const tagManagerArgs = {
//   gtmId: 'GTM-TQSS9S'
// }
// TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <ToastContainer />
        <Router>
          <Routers />
        </Router>
      </Provider>
    </Suspense>
  )
}

export default App
