import { FC, ReactNode, useRef } from "react";

interface Props {
  children: ReactNode;
  tooltip?: string;
}

const ToolTip: FC<Props> = ({ children, tooltip }): JSX.Element => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!tooltipRef.current || !container.current) return;
        const { left } = container.current.getBoundingClientRect();

        tooltipRef.current.style.left = clientX - left + "px";
      }}
      className="group relative inline-block"
    >
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          className="hidden group-hover:block opacity-0 group-hover:opacity-100 transition bg-white text-[#212121] border border-[#212121] p-2 rounded-tr-lg rounded-br-lg rounded-bl-lg absolute top-full mt-2 md:whitespace-nowrap text-sm min-w-[200px] md:min-w-0"
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  );
};

export default ToolTip;
