import { FC } from "react";
import { NotificationOffcanvasProps } from "./types";
import { FiX } from "react-icons/fi";
import { NotificationDataModel } from "../../redux/notifications/types";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { StoreCommand, useStore } from "../../redux/store";

const token = Cookies.get("tokenY");

const NotificationOffcanvas: FC<NotificationOffcanvasProps> = ({ show, toggleShow }) => {
    const [store, dispatch] = useStore((state) => state.notifications?.list);
    const navigate = useNavigate();

    const handleReadAll = () => {
        dispatch(
            StoreCommand.notifications.putReadAllNotification({
                token: token ? atob(token) : ""
            })
        );
    }

    const handleReadSingle = (obj: NotificationDataModel) => {
        const id = obj.id;

        dispatch(
            StoreCommand.notifications.putReadNotificationById({
                id,
                token: token ? atob(token) : ""
            })
        );

        if (obj.data?.JobId) {
            navigate(`/profile/job?userId=${obj.data?.JobId?.toUpperCase()}`);
        } else {
            navigate('/profile/dashboard');
        }
        toggleShow();
    }

    return (
        <>
            <div className={`fixed top-0 left-0 z-[1044] bg-[#86868670] w-[100vw] h-[100vh] ${show ? 'visible' : 'invisible'}`} />
            <div
                className={`min-h-full fixed bottom-0 -right-0 top-0 z-[1045] flex w-96 max-w-full translate-x-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out dark:bg-neutral-800 dark:text-neutral-200 ${show ? 'transform-none' : 'invisible'}`}
            >
                <div className="flex items-center gap-4 p-8">
                    <div className="text-[#212121] font-semibold">
                        Notifications
                    </div>
                    <div className="text-sm text-[#0097DA] cursor-pointer" onClick={handleReadAll}>
                        Read All
                    </div>
                    <div className="ms-auto cursor-pointer" onClick={toggleShow}>
                        <FiX size={20} />
                    </div>
                </div>
                <div className="max-h-full overflow-y-auto">
                    {store?.map((o: NotificationDataModel) => {
                        return (
                            <div className={`px-8 py-4 cursor-pointer hover:bg-[#E5F5FB80] ${o.readAt ? '' : 'bg-[#E5F5FB]'}`} key={o.id} onClick={() => handleReadSingle(o)}>
                                <div className={`text-sm mb-2 ${o.readAt ? 'text-[#868686]' : 'font-semibold text-[#0097DA]'}`}>
                                    {o.wording.title}
                                </div>
                                <div className={`text-sm mb-3 ${o.readAt ? 'text-[#868686]' : 'text-[#343536]'}`}>
                                    {o.wording.body}
                                </div>
                                <div className="text-xs text-[#868686]">
                                    {dayjs(o.createdAt).format("DD MMMM YYYY, HH:mm")}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

NotificationOffcanvas.displayName = 'NotificationOffcanvas';
export { NotificationOffcanvas };
