import React from "react";
import Buttons from "../../components/buttons";
import IconPeopleGrafix from "../../assets/BInus/brands/people-grafik.svg";
import Dot from "../../components/dot";

export default function SectionTwo() {
  return (
    <div className="bg-[url('/src/assets/BInus/brands/3.svg')] bg-cover">
      <div className="max-w-6xl mx-auto px-7">
        <div className="pt-40 flex flex-wrap gap-4">
          <div className="w-[600px]">
            <p className="text-[36px] font-bold lg:text-[44px]">Why You Should Use</p>
            <p className="text-[36px] font-bold lg:text-[44px] bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
              BINUSFLUENCER
            </p>
            <div className="mt-12">
              <p className="title-sm text-[#212121]">
                Run your brand marketing campaigns in one go. We offer you a
                tool to:
              </p>
              <div className="mt-4 ml-1">
                {[1, 2, 3].map(() => {
                  return (
                    <div className="flex items-center my-3">
                      <Dot className="mr-4" />
                      <div>
                        <p className="text-sm text-[#212121]">
                          Monitor your campaign performance and get insights
                          from the data.
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="my-8">
              <Buttons label="Start Now" className="w-[172px]" />
            </div>
          </div>
          <div className="hidden lg:block">
            <img src={IconPeopleGrafix} alt="icons" />
          </div>
        </div>
      </div>
    </div>
  );
}
