/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { FiSearch } from "react-icons/fi";
import { debounce } from "lodash";

import { THitparamsCampaign } from "../../types";
import SortBy from "./sortBy";

interface TSearch {
  setHitParams: (props: THitparamsCampaign) => void;
  hitParams: THitparamsCampaign;
}

export default function FilterCampaign({ setHitParams, hitParams }: TSearch) {
  const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    setHitParams({
      ...hitParams,
      Search: e.target.value,
      Page: 1
    });
  }, 500);

  return (
    <div className="flex flex-wrap justify-center sm:justify-between gap-3">
      <div className="w-full sm:w-80">
        <div className="relative">
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 text-sm text-[#868686] border border-[#E6E6E6] rounded-[50px] bg-white focus:outline-none"
            placeholder="Search "
            defaultValue={hitParams.Search}
            onChange={handleChange}
          />
          <button
            type="submit"
            className="text-white absolute rounded-full right-2.5 top-1/2 -translate-y-1/2 bg-[#0097DA] focus:ring-4 focus:outline-none font-medium text-sm p-3"
          >
            <FiSearch />
          </button>
        </div>
      </div>
      <div className="flex items-center">
        <div className="px-3 title-sm">Sort By</div>
        <SortBy setHitParams={setHitParams} hitParams={hitParams} />
      </div>
    </div>
  );
}
