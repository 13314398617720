/* eslint-disable no-useless-concat */
import { toast } from "react-toastify";
import fetchAxios from "../../helpers/fetchAxios";
import { DETAILCAMPAIGN, LISTCAMPAIGN, LISTPLATFORM } from "./typeAction";

type TListPlatform = {
  path: string;
  token: string;
  setListPlatform?: any;
};

type TListCampaign = {
  path: string;
  token: string;
  params?: any;
};

type TDetailCampaign = {
  path: string;
  token: string;
  id: any;
  callBack?: any;
};

type TJoinCampaign = {
  path: string;
  token: string;
  id: any;
  callBack: any;
};

type TRespondOffer = {
  path: string;
  token: string;
  id: any;
  userId: string;
  callBack: any;
};

export const getListPlatform = (props: TListPlatform) => {
  const { path, token, setListPlatform } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path + "/Platform?TAKE=10&PAGE=1&SortDirection=true&OrderBy=name",
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data?.rows;
      setListPlatform(res);
      dispatch({
        type: LISTPLATFORM,
        payload: res,
      });
    });
  };
};

export const getListCampaign = (props: TListCampaign) => {
  const { path, token, params } = props;
  return (dispatch: any) => {
    fetchAxios({
      url:
        path +
        `?Page=${params.Page}&Take=${params.Take}&OrderBy=${params.OrderBy}&SortDirection=${params.SortDirection}&Platform=${params.Platform}&MaxStartfrom=${params.MaxStartfrom}&MinStartfrom=${params.MinStartfrom}&Search=${params.Search}`,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response?.data?.data;
      if (response.status === 200) {
        dispatch({
          type: LISTCAMPAIGN,
          payload: res,
        });
      }
    });
  };
};

export const getDetailCampaign = (props: TDetailCampaign) => {
  const { path, token, id, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path + `/${id}` + "/show",
      method: "get",
      token: token,
    }).then((response) => {
      const res = response?.data?.data;
      if (response.status === 200) {
        dispatch({
          type: DETAILCAMPAIGN,
          payload: res,
        });
        callBack();
      }
    });
  };
};

export const postJoinCampaign = (props: TJoinCampaign) => {
  const { path, token, id, callBack } = props;

  return () => {
    fetchAxios({
      url: path + `/${id}` + "/apply",
      method: "post",
      token: token,
      body: {},
    }).then((response) => {
      if (response.status === 200) {
        callBack(response.status);
        toast.success("Berhasil join campaign");
      } else {
        callBack(response.status);
        toast.error("Gagal join campaign");
      }
    });
  };
};

export const putAcceptOffer = (props: TRespondOffer) => {
  const { path, token, id, userId, callBack } = props;

  return () => {
    fetchAxios({
      url: path + `/${id}` + "/accept",
      method: "put",
      token: token,
      body: {
        binusId: userId
      },
    }).then((response) => {
      if (response.status === 200) {
        callBack(response.status);
        toast.success("Success");
      } else {
        callBack(response.status);
        toast.error("Error");
      }
    });
  };
};

export const putDeclineOffer = (props: TRespondOffer) => {
  const { path, token, id, userId, callBack } = props;

  return () => {
    fetchAxios({
      url: path + `/${id}` + "/decline",
      method: "put",
      token: token,
      body: {
        binusId: userId
      },
    }).then((response) => {
      if (response.status === 200) {
        callBack(response.status);
        toast.success("Success");
      } else {
        callBack(response.status);
        toast.error("Error");
      }
    });
  };
};
