import { TCategory } from "../types";

const initialState: TCategory = {
    listCategory: [],
}

const masterReducer = (state: TCategory = initialState, action: {type: string, payload: any}) => {
    switch(action.type) {
        case 'LIST_SUCCESS_CATEGORY':
            return {
                ...state,
                listCategory: action.payload
            }
        default:
            return state;
    }
}

export default masterReducer;