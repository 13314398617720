import React from "react";

import SectionTree from "./sectionTree";
import HeaderBanner from "./headerBanner";
import SectionOne from "./sectionOne";
import SectionFour from "./sectionFour";
import SectionTwo from "./sectionTwo";

export default function ForBrand() {
  return (
    <div className="bg-white">
      <HeaderBanner />
      <SectionOne />
      <SectionTwo />
      <SectionTree />
      <SectionFour />
    </div>
  );
}
