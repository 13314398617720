import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import Inputs from "../../../components/inputs";
import { IoChevronBackOutline } from "react-icons/io5";
import Buttons from "../../../components/buttons";
import fetchAxios from "../../../helpers/fetchAxios";
import environments from "../../../environments";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const schema = yup.object({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is a required field"),
});

type FormData = {
  email: string;
};

export default function Forgot(props: any) {
  const { setTypeSignIn } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ resolver: yupResolver(schema) });
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    fetchAxios({
      url: environments.api.forgotFluencer + "/ForgotPassword",
      method: "post",
      body: {
        email: data.email,
      },
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.data?.errorMessage);
        navigate(`/auth/verification/${data.email}`);
      }
    });
  };

  return (
    <div className="flex min-h-full items-center justify-center h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-sm">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white shadow-lg px-8 pt-6 pb-8 mb-4 rounded-2xl"
        >
          <div className="text-base font-bold leading-8 text-[#343536]  pt-5">
            <p>Forgot Password</p>
          </div>
          <div className="flex items-center pt-4 cursor-pointer">
            <div className="pr-2">
              <IoChevronBackOutline className="text-[#0097DA]" />
            </div>
            <div onClick={() => setTypeSignIn("signinas")}>
              <p className="text-sm leading-5 text-[#0097DA]">Back</p>
            </div>
          </div>
          <div className="pt-8">
            <p className="title-sm text-[#343536]">
              No worries, we’ll send you reset instructions.
            </p>
          </div>
          <div className="pt-10">
            <Inputs
              register={register}
              label="Email"
              type="text"
              name="email"
              errors={errors}
            />
            <div className="pt-12">
              <Buttons label="Send" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
