import React from "react";
import { NavLink } from "react-router-dom";
import Buttons from "../../components/buttons";
import { START_PROJECT } from "../../routes/listRoute";

export default function SectionFour() {
  return (
    <>
      <div className="max-w-6xl mx-auto lg:pb-[120px]">
        <div className="bg-[url('/src/assets/BInus/for-brand/unsplash_FlPc9_VocJ4.svg')] bg-cover bg-no-repeat w-auto lg:w-[1176px] min-h-[460px]">
          <div className=" bg-black lg:w-[1176px] min-h-[460px] flex items-center justify-center lg:rounded-xl opacity-[0.8]">
            <div className="text-center px-1">
              <p className="text-[36px] font-bold lg:text-[44px] text-white mb-5">
                Why not trying today for free?
              </p>
              <p className="text-lg text-white font-normal mb-14">
                Get started by taking a tour into our platform.
              </p>
              <div>
                <NavLink to={START_PROJECT}>
                  <Buttons label="Start Now" className="w-[172px]" />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
