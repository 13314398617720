import React from "react";
import { listBenefits } from "../home/sectionTwo";

export default function SectionTree() {
  return (
    <div className=" h-auto sm:h-[598px] bg-[url('/src/assets/BInus/for-binusfluen/section-tree.svg')] bg-cover bg-no-repeat ">
      <div className="max-w-6xl mx-auto px-3">
        <div className="flex items-center px-4 lg:px-0 h-auto p-10 lg:pt-28">
          <div>
            <div className="flex justify-center">
              <p className="text-[36px] font-bold lg:text-[44px]">
                The{" "}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
                  Benefits
                </span>
              </p>
            </div>
            <div className="grid grid-cols-2 gap-10 pt-[64px]">
              {listBenefits.map((val: any, i: any) => {
                return (
                  <div className="flex flex-wrap md:items-center gap-3" key={i}>
                    <div className="w-[64px] h-[64px] bg-gradient-to-r from-[#0097DA] to-[#174479] flex items-center justify-center rounded-full">
                      <img src={val.icon} alt="icon" />
                    </div>
                    <div className="w-[460px]">
                      <p className="text-base font-bold text-[#343536] pb-3">
                        {val.title}
                      </p>
                      <p className="title-sm text-[#212121]">{val.subtitle}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
