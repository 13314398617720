import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/buttons";
import { AUTH_REGISTER } from "../../routes/listRoute";
import { listCategory } from "../home/sectionTwo";

export default function SectionTwo() {
  const navigate = useNavigate();
  let tokenBase64 = Cookies.get("tokenY");
  const handleButton = () => {
    if (!tokenBase64) {
      navigate(AUTH_REGISTER);
    }
  };
  return (
    <div className="bg-white">
      <div className="max-w-6xl mx-auto px-3">
        <div className="px-4 lg:px-0 h-auto sm:h-[598px] relative">
          <div className="w-full lg:w-[660px] pt-14 pb-12 md:pb-0 md:pt-28">
            <p className="text-[36px] font-bold lg:text-[44px] mb-4">
              Siapa saja yang bisa menjadi bagian dari{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479]">
                BINUSFLUENCER ?
              </span>
            </p>
            <div className="sm:w-[550px]">
              <p className=" text-sm text-[#212121] font-normal tracking-widest">
                Buat kamu, BINUSIAN, khususnya <strong>mahasiswa</strong> dan <strong>alumni</strong> yang aktif di dunia media sosial bisa menjadi bagian BINUSFLUENCER.
              </p>
            </div>
            {/* <div className="w-full md:w-[390px] my-5">
              <div className="flex flex-wrap gap-3">
                {listCategory.map((val: any, i: any) => {
                  return (
                    <div className="w-[166px]" key={i}>
                      <ul className="list-disc pl-4 marker:text-[#0097DA] ring-offset-4 ring-offset-slate-50  text-[#212121] text-sm">
                        <li>{val.name}</li>
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div> */}
            {!tokenBase64 && (
              <div className="mt-10 flex flex-wrap items-center gap-5">
                <Buttons
                  label="Register Now"
                  className="w-[163px]"
                  onClick={handleButton}
                />
              </div>
            )}
          </div>
          <div className=" absolute inset-y-0 right-0 w-3/6 hidden xl:block bg-[url('/src/assets/BInus/for-binusfluen/section-two.svg')] bg-cover bg-no-repeat bg-center h-auto sm:h-[504px]" />
        </div>
      </div>
    </div>
  );
}
