/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink } from "react-router-dom"
import LogoBinuWhite from "../../assets/svg/binus-white.svg"
import IconFB from "../../assets/svg/icon-fb-tranfarans.svg"
import IconLinked from "../../assets/svg/icon-linked-tranfanrans.svg"
import IconIG from "../../assets/svg/ig-transfarans.svg"
import IconLocation from "../../assets/svg/location.svg"
import IconPhone from "../../assets/svg/phone.svg"
import { HOME, HOW_TO_REGISTER, PRIVACYPOLICY, TANDC } from "../../routes/listRoute"
import { BINUS_SOCMED_URLS, listMenuFooter, listMoreFooter } from "../../utilities/constants"

export default function Footer() {
  return (
    <footer
      className="p-4 bg-white sm:p-6 "
      style={{
        background: "linear-gradient(93.96deg, #0097DA 0%, #174479 100.24%)",
      }}
    >
      <div className="max-w-6xl mx-auto">
        <div className="lg:flex lg:justify-between">
          {/* <div className="grid grid-cols-2 gap-8"> */}
          <div className="grid grid-cols-1 gap-8 sm:gap-10 md:grid-cols-2 lg:grid-cols-4 ">
            {/* logo binus */}
            <div className="max-w-[272px] ">
              <a href="/home" className="flex items-center">
                <img
                  src={LogoBinuWhite}
                  className="mr-3 h-8"
                  alt="FlowBite Logo"
                />
              </a>
              <div>
                <p className="text-white text-sm">
                  BINUSFLUENCER adalah program yang mengajak BINUSIAN untuk
                  berkontribusi aktif dalam membina masyarakat Indonesia yang
                  lebih maju.
                </p>
              </div>
            </div>
            {/* explore */}
            <div className="max-w-[272px] lg:flex lg:justify-center lg:flex-wrap">
              <div>
                <h4 className="font-bold text-white md:mt-0 mb-2">General</h4>
                <ul className="text-gray-600 dark:text-gray-400">
                  {listMenuFooter.map((value: any, i: any) => {
                    return (
                      <li className="mb-4" key={i}>
                        <NavLink
                          to={value.link}
                          className="text-white text-sm font-normal"
                        >
                          {value.label}
                        </NavLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            {/* more info */}
            <div className="max-w-[272px] ">
              <div>
                <h4 className="font-bold text-white md:mt-0 mb-2">More Info</h4>
                <ul className="text-gray-600 dark:text-gray-400">
                  {listMoreFooter.map((value: any, i: any) => {
                    return (
                      <li className="mb-4" key={i}>
                        <NavLink
                          to={value.link}
                          className="text-white text-sm font-normal"
                        >
                          {value.label}
                        </NavLink>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            {/* help */}
            <div className="max-w-[272px]">
              <h4 className="font-bold text-white mt-4 md:mt-0 mb-2">
                Reach Us Through
              </h4>
              <div className="flex mb-3">
                <div className="mr-2 pt-1 hidden lg:block">
                  <img
                    src={IconLocation}
                    alt="location"
                    width={30}
                    height={30}
                  />
                </div>
                <div>
                  <p className="text-white text-sm font-normal">
                    Jl. K. H. Syahdan No. 9, Kemanggisan, Palmerah Jakarta 11480
                    Indonesia
                  </p>
                </div>
              </div>
              <div className="flex mb-4">
                <div className="mr-2 pt-1 hidden lg:block">
                  <img src={IconPhone} alt="phone" width={30} height={30} />
                </div>
                <div>
                  <p className="text-white text-sm font-normal">
                    Phone +62 21 534 5830, +62 21 535 0660 Fax +62 21 530 0244
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <a
                  href={BINUS_SOCMED_URLS.facebook}
                  target="blank"
                  rel="noreferer"
                >
                  <img src={IconFB} alt="facebook" />
                </a>
                <a
                  href={BINUS_SOCMED_URLS.linkedin}
                  target="blank"
                  rel="noreferer"
                >
                  <img src={IconLinked} alt="linkedin" />
                </a>
                <a
                  href={BINUS_SOCMED_URLS.instagram}
                  target="blank"
                  rel="noreferer"
                >
                  <img src={IconIG} alt="Instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-[#5B96B0] sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-white sm:text-center ">
            © 2022{" "}
            <a href={HOME} className=" font-semibold text-sm">
              BINUSFLUENCER
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}
