import Cookies from "js-cookie";
import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/buttons";
import { AUTH_SIGNIN, CAMPAIGN } from "../../routes/listRoute";

export default function SectionOne() {
  const navigate = useNavigate();
  let tokenBase64 = Cookies.get("tokenY");

  const handleButton = () => {
    if (tokenBase64) {
      navigate(CAMPAIGN);
    } else {
      navigate(AUTH_SIGNIN);
    }
  };
  return (
    <div className=" h-auto sm:h-[659px] bg-[url('/src/assets/BInus/for-binusfluen/Frame-1400.svg')] bg-cover bg-no-repeat">
      <div className="max-w-6xl mx-auto px-3">
        <div className="flex items-center py-12 md:pb-0 md:pt-28 px-4 lg:px-0">
          <div className="w-full sm:w-[550px]">
            <p className="text-[36px] font-bold lg:text-[44px] mb-4">
              About{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479]">
                BINUSFLUENCER
              </span>
            </p>
            <p className=" text-sm text-[#212121] font-normal tracking-widest">
              BINUSFLUENCER adalah program yang disediakan oleh Tim BINUSFLUENCER sebagai wadah komunitas influencer internal BINUS HIGHER EDUCATION di mana influencer yang tergabung di dalamnya dapat mengikuti berbagai campaign yang tersedia dan mendapatkan reward sesuai ketentuan yang diinformasikan pada campaign terkait.
            </p>
            <div className="mt-10 flex flex-wrap items-center gap-5">
              <Buttons
                label="Start Project"
                className="w-[163px]"
                onClick={handleButton}
              />
              <div
                className="flex items-center cursor-pointer"
                onClick={handleButton}
              >
                <p className="text-[#343536] text-xs">See All Campaign</p>
                <span className="pl-2">
                  <FiChevronRight className="text-[#343536]" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
