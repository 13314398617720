import { debounce } from "lodash";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/hooks";
import { NAVBAR_SEARCH_CAMPAIGN } from "../../redux/campaign/typeAction";
import { CAMPAIGN } from "../../routes/listRoute";

export default function Search() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NAVBAR_SEARCH_CAMPAIGN,
      payload: e.target.value
    });
    if (location.pathname !== CAMPAIGN) {
      navigate(CAMPAIGN);
    }
  }, 500);
  return (
    <div className="block my-5 lg:my-0">
      <div className="relative">
        <input
          type="search"
          id="default-search"
          className="block w-[520px] p-3 text-sm text-[#868686] border border-[#E6E6E6] focus:outline-none rounded-[50px] bg-white"
          placeholder="Search Campaign "
          onChange={handleChange}
        />
        <button
          type="submit"
          className="text-white absolute rounded-full right-2.5 bottom-1 bg-[#0097DA] focus:ring-4 focus:outline-none font-medium text-sm p-3"
        >
          <FiSearch />
        </button>
      </div>
    </div>
  );
}
