import React from "react";
import IconOne from "../../assets/BInus/brands/finger-one.svg";
import IconTwo from "../../assets/BInus/brands/finger-two.svg";
import IconTree from "../../assets/BInus/brands/finger-tree.svg";
import IconFour from "../../assets/BInus/brands/finger-four.svg";

export const listHowItWork = [
  {
    title: "Set Your Goals",
    subtitle: "Define strategies based on key objective metrics.",
    icons: IconOne,
  },
  {
    title: "Campaign Management",
    subtitle: "Set your marketing campaign with specific criteria.",
    icons: IconTwo,
  },
  {
    title: "Discover The MostRelevant Influencers",
    subtitle: "Connect and collaborate with the right influencers.",
    icons: IconTree,
  },
  {
    title: "Measurment & Reporting",
    subtitle: "View impressions, reach, and all engagements.",
    icons: IconFour,
  },
];

export default function SectionOne() {
  return (
    <div className="bg-[url('/src/assets/BInus/brands/2.png')] bg-auto bg-no-repeat bg-center">
      <div className="max-w-6xl mx-auto px-3">
        <div className="pt-28 flex justify-center">
          <p className="text-[36px] font-bold lg:text-[44px]">
            How It{" "}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
              Works
            </span>
          </p>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-end gap-5 lg:gap-20 py-[56px]">
          {listHowItWork.map((val: any) => {
            return (
              <div className="w-[270px]  py-6 pl-4 pr-2 rounded-lg hover:bg-white hover:drop-shadow-[0px_4px_16px_rgba(0,151,218,0.12)]">
                <div className="w-[64px] h-[64px] bg-gradient-to-r from-[#0097DA] to-[#174479] flex items-center justify-center rounded-full">
                  <img src={val.icons} alt={val.title} />
                </div>
                <div className="mt-4">
                  <p className=" text-base font-bold text-[#343536] py-3">
                    {val.title}
                  </p>
                  <p className="title-sm text-[#212121]">{val.subtitle}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
