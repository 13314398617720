import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Disclosure } from "@headlessui/react";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";
import fetchAxios from "../../helpers/fetchAxios";
import environments from "../../environments";

export default function Faq() {
  const [faqList, setFaqList] = useState([]),
    [searchTerm, setSearchTerm] = useState('');

  const fetchFAQ = (search?: string) => {
    const url = `${environments.api.listFAQ}${search ? `?search=${search}` : ''}`;

    fetchAxios({
      method: "get",
      url: url,
    }).then((response) => {
      setFaqList(response.data.data.rows);
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchFAQ();
  }, []);

  return (
    <div className="min-h-screen bg-[url('/src/assets/svg/faq-bg.svg')] bg-cover bg-no-repeat">
      <div className="md:max-w-6xl mx-auto min-h-screen px-8 md:px-20 py-[140px]">
        <div className="flex flex-wrap justify-center">
          <div className="text-center">
            <p className="text-[44px] text-[#343536] font-bold">
              Frequently Asked{" "}
              <span className="text-[44px] font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
                Questions
              </span>
            </p>
            <p className="my-4 text-center title-sm text-base">
              Have questions? Let us help you!
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-16 w-full sm:w-[800px]">
            <div className="block my-5 lg:my-0">
              <div className="relative">
                <input
                  type="search"
                  id="default-search"
                  className="block w-full p-4 text-sm text-[#868686] border border-[#E6E6E6] focus:outline-none rounded-[50px] bg-white"
                  placeholder="What are you looking for?... "
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => (e.code === 'Enter' ? fetchFAQ(searchTerm) : null)}
                />
                <button
                  type="submit"
                  className="text-white absolute rounded-full right-2.5 top-1/2 -translate-y-1/2 bg-[#0097DA] focus:ring-4 focus:outline-none font-medium text-sm p-3"
                  onClick={() => fetchFAQ(searchTerm)}
                >
                  <FiSearch />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className=" min-w-fit lg:min-w-[960px] mt-16">
          {faqList?.map((val: any, i: any, row: any) => {
            return (
              <Disclosure
                as="div"
                className={`${
                  i + 1 === row.length
                    ? "border-0"
                    : "border-b border-[#D9D9D9]"
                }`}
              >
                {({ open }) => {
                  return (
                    <>
                      <Disclosure.Button className="flex items-center justify-between w-full py-5 text-left text-[#0097DA] text-base font-semibold ">
                        <span className="text-[#0097DA] text-sm">
                          <div
                            className="list-default"
                            dangerouslySetInnerHTML={{ __html: val?.question }}
                          />
                        </span>
                        {open ? (
                          <FiPlusCircle className="min-w-[22px] min-h-[22px] text-[#868686]" />
                        ) : (
                          <FiMinusCircle className="min-w-[22px] min-h-[22px] text-[#868686]" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className=" pt-4 pb-2 text-sm text-[#343536]">
                        <div
                          className="list-default pb-5"
                          dangerouslySetInnerHTML={{ __html: val?.answer }}
                        />
                      </Disclosure.Panel>
                    </>
                  );
                }}
              </Disclosure>
            );
          })}
        </div>
      </div>
    </div>
  );
}
