import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { InputRegisTypes, InputTypes } from "../../types";

export default function Inputs(props: InputTypes) {
  const {
    label,
    type,
    name,
    errors,
    placeholder,
    register,
    requiredLabel,
    clasName,
    onBlur,
    disabled = false
  } = props;
  const [showPassword, setshowPassword] = useState(false);
  let tipe: string = type;
  if (type === "passsword" || showPassword) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tipe = "text";
  }
  return (
    <>
      {label && (
        <label className="block text-[#343536] text-sm leading-5 mb-1">
          {label} {requiredLabel ? <span className="text-red-600">*</span> : ""}
        </label>
      )}
      <div className="relative">
        <input
          className={`input disabled:bg-red focus:outline-[#0097DA] focus:shadow-outline focus:shadow-lg h-[48px] ${clasName}`}
          disabled={disabled}
          {...register(name)}
          type={tipe}
          placeholder={placeholder}
          onBlur={onBlur}
        />
        {type === "password" ? (
          <div>
            {showPassword ? (
              <FiEye
                className="absolute top-[20px] right-2 cursor-pointer text-[#0097DA]"
                onClick={() => setshowPassword(!showPassword)}
              />
            ) : (
              <FiEyeOff
                className="absolute top-[15px] right-2 cursor-pointer border-[#0097DA] text-[#0097DA] "
                onClick={() => setshowPassword(!showPassword)}
              />
            )}
          </div>
        ) : null}
        <p className="text-sm text-[#e11d48]">{errors[name]?.message}</p>
      </div>
    </>
  );
}

export const InputsRegister = (props: InputRegisTypes) => {
  const { label, type, name, errors, placeholder, onChange } = props;

  const [showPassword, setshowPassword] = useState(false);

  let tipe: string = type;
  if (type === "passsword" || showPassword) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tipe = "text";
  }
  return (
    <>
      <label className="block text-[#343536] text-sm leading-5 mb-1">
        {label}
      </label>
      <div className="relative">
        <input
          className="input focus:outline-none focus:shadow-outline"
          name={name}
          onChange={onChange}
          type={tipe}
          placeholder={placeholder}
        />
        {type === "password" ? (
          <div>
            {showPassword ? (
              <FiEyeOff
                className="absolute top-[10px] right-2 cursor-pointer border-[#0097DA]"
                onClick={() => setshowPassword(!showPassword)}
              />
            ) : (
              <FiEye
                className="absolute top-[10px] right-2 cursor-pointer"
                onClick={() => setshowPassword(!showPassword)}
              />
            )}
          </div>
        ) : null}
        <p>{errors[name]?.message}</p>
      </div>
    </>
  );
};
