export const LISTJOBS = "LISTJOBS";
export const LISTONGOING = "LISTONGOING";
export const LISTEARNING = "LISTEARNING";
export const LISTSHOWPROFILE = "LISTSHOWPROFILE";
export const LIST_SHOW_PROFILE_SOCIAL_MEDIAS = "LIST_SHOW_PROFILE_SOCIAL_MEDIAS";
export const LISTMAJOR = "LISTMAJOR";
export const LISTACCOUNTBANK = "LISTACCOUNTBANK";
export const ACCOUNTBANK = "ACCOUNTBANK";
export const LISTACCOUNTBANKALL = "LISTACCOUNTBANKALL";
export const LISTJOBDRAFT = "LISTJOBDRAFT";
export const LISTPOSTCONTENT = "LISTPOSTCONTENT";
export const USERS = "USERS";
export const DETAILJOBS = "DETAILJOBS";
export const ADDED_SOCIAL_MEDIA_ACCOUNTS = "ADDED_SOCIAL_MEDIA_ACCOUNTS";
export const DETAIL_EARNING = "DETAIL_EARNING";
export const DETAIL_JOB_PERFORMANCE = 'DETAIL_JOB_PERFORMANCE';
export const GET_KTP_NPWP = 'GET_KTP_NPWP';
