import { DETAILCAMPAIGN, LISTCAMPAIGN, LISTPLATFORM, NAVBAR_SEARCH_CAMPAIGN } from "./typeAction";

type Campaign = {
  flatform: string[];
  dataCampaign: object;
  loadingMainPage: boolean;
  detailCampaign?: any
  loadingDetailCampaign?: boolean
  navbarSearch: string
};
type TAction = {
  type: string;
  payload?: any;
};

const initialState: Campaign = {
  flatform: [],
  dataCampaign: {},
  loadingMainPage: true,
  detailCampaign: [],
  loadingDetailCampaign: false,
  navbarSearch: ''
};

const campaignReducer = (state: Campaign = initialState, action: TAction) => {
  switch (action.type) {
    case LISTPLATFORM:
      return {
        ...state,
        flatform: action.payload
      }
    case LISTCAMPAIGN:
      return {
        ...state,
        dataCampaign: action.payload,
        loadingMainPage: false
      }
    case DETAILCAMPAIGN:
      return {
        ...state,
        detailCampaign: action.payload,
        loadingDetailCampaign: true
      }
    case NAVBAR_SEARCH_CAMPAIGN:
      return {
        ...state,
        navbarSearch: action.payload
      }

    default:
      return state;
  }
};

export default campaignReducer;
