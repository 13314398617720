import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FiCheck, FiChevronDown } from "react-icons/fi";

const listSort = [{ name: "Desc" }, { name: "Asc" }];

interface TSort {
  setHitParams: any;
  hitParams: any;
}

export default function SortBy(props: TSort) {
  const { setHitParams, hitParams } = props;
  const [selected, setSelected] = useState(listSort[0]);

  const handleSelect = (name: string) => {
    setHitParams({
      ...hitParams,
      SortDirection: name === "Desc" ? true : false,
    });
  };

  return (
    <div className="w-[186px]">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative  w-full cursor-default rounded-full h-[52px] bg-white py-[18px] pl-[20px] pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block text-sm font-normal truncate text-[#868686]">
              {selected.name}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <FiChevronDown className="text-[#000000] w-[20px] h-[20px]" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {listSort.map((item, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-[#0097DA] text-gray-900 " : "text-gray-900"
                    }`
                  }
                  value={item}
                  onClick={() => handleSelect(item.name)}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-900">
                          <FiCheck />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
