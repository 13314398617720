/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";

import FilterCampaign from "./filter";
import SideBarCampaign from "./sideBar";
import { CAMPAIGN } from "../../routes/listRoute";
import { getListCampaign, getListPlatform } from "../../redux/campaign/action";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import environments from "../../environments";
import Cookies from "js-cookie";
import { THitparamsCampaign } from "../../types";
import SkeletonMedia from "../loading/skeletonMedia";
import { formatRp } from "../../helpers";
import platForm from "../../components/platForm/platForm";
import { TCampaign } from "../../types/campaign";
import { NAVBAR_SEARCH_CAMPAIGN } from "../../redux/campaign/typeAction";

const initialState = {
  Page: 1,
  Take: 9,
  OrderBy: "id",
  SortDirection: true,
  Platform: "",
  MaxStartfrom: "",
  MinStartfrom: "",
  Search: "",
};

let currentPage = 1;

export default function Campaign() {
  const token = Cookies.get("tokenY");
  const dataCampaign = useAppSelector((state) => state.campaign);
  const dispatch = useAppDispatch();

  const [hitParams, setHitParams] = useState<THitparamsCampaign>();
  const [listPlatform, setListPlatform] = useState();

  useEffect(() => {
    return () => {
      dispatch({
        type: NAVBAR_SEARCH_CAMPAIGN,
        payload: "",
      });
    };
  }, []);

  useEffect(() => {
    if (dataCampaign.navbarSearch) {
      setHitParams({
        ...initialState,
        Search: dataCampaign.navbarSearch,
        Page: 1,
      });
    } else {
      setHitParams(initialState);
    }
  }, [dataCampaign.navbarSearch]);

  useEffect(() => {
    if (hitParams) {
      window.scrollTo(0, 0);
      dispatch(
        getListPlatform({
          path: environments.api.misc,
          token: token ? atob(token) : "",
          setListPlatform: setListPlatform,
        })
      );
      dispatch(
        getListCampaign({
          path: environments.api.listCampaign,
          token: token ? atob(token) : "",
          params: hitParams,
        })
      );
      currentPage = hitParams.Page;
    }
  }, [hitParams]);

  const handleChangePagination = (_: any, number: number) => {
    if (hitParams) {
      setHitParams({
        ...hitParams,
        Page: number,
      });
    }
  };

  return (
    <div className="flex flex-wrap md:flex md:flex-nowrap justify-center gap-x-0 sm:gap-x-10 md:gap-x-3 ">
      {hitParams ? (
        <SideBarCampaign
          setHitParams={setHitParams}
          hitParams={hitParams}
          listPlatform={listPlatform}
          initialState={initialState}
        />
      ) : null}
      <div className="w-full px-4 lg:px-0">
        {hitParams ? (
          <FilterCampaign setHitParams={setHitParams} hitParams={hitParams} />
        ) : null}
        {dataCampaign.loadingMainPage &&
        dataCampaign?.dataCampaign?.totalData > 0 ? (
          Array.from({ length: 6 }, () => <SkeletonMedia />)
        ) : dataCampaign?.dataCampaign?.rows?.length <= 0 ? (
          <DataNull />
        ) : (
          <div className="my-6 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-10">
            {dataCampaign?.dataCampaign?.rows?.map(
              (val: TCampaign, i: number) => {
                const encodedUrl = encodeURI(val.banner);
                return (
                  <NavLink
                    key={val.id}
                    to={`${CAMPAIGN}/${val.id}`}
                    className="cards p-0 w-full xl:w-[271px] rounded-2xl overflow-hidden cursor-pointer transform transition duration-700 hover:scale-110 flex flex-col"
                  >
                    <div
                      className="relative h-[194px] bg-red-500 flex justify-center items-center text-center px-6 bg-cover"
                    >
                      <img className='absolute left-0 top-0 w-full h-[194px] object-cover' src={encodedUrl} />
                      <div className="z-10">
                        <p className="text-2xl text-white font-bold line-clamp-3">
                          {val.title}
                        </p>
                        <p className="font-normal text-[10px] opacity-70 text-[#FFFFFF]">
                          {val.to}
                        </p>
                      </div>
                    </div>
                    <div className="px-4 my-5 flex flex-col grow">
                      <p className="title-sm py-2">{val.brandName}</p>
                      <p className="text-[#2F3335] text-base my-2 font-semibold line-clamp-3">
                        {val.title}
                      </p>
                      <p className="title-sm py-2">Deadline Post</p>
                      <p className="text-[#2F3335] text-base py-2 font-semibold grow">
                        {dayjs(val.startCampaign).format("DD MMM YYYY")}
                      </p>
                      <hr className="border-dashed border-[#D5D5D5] py-2" />
                      <div className="flex flex-wrap gap-1 justify-center items-center">
                        {val.platform?.map((item: any, i: number) => {
                          return (
                            <Fragment key={`platform-${i}`}>
                              {platForm(item?.name ? item?.name : "")}
                            </Fragment>
                          );
                        })}
                        <div className="px-1">
                          <p className="title-sm">Start From</p>
                        </div>
                        <div>
                          <p className="text-[#0097DA] font-base font-semibold">
                            {`Rp ${formatRp(val.startPrice)}`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                );
              }
            )}
          </div>
        )}
        <div className="flex justify-center py-20">
          {dataCampaign?.dataCampaign?.rows?.length > 0 && (
            <Stack spacing={4}>
              <Pagination
                count={dataCampaign.dataCampaign?.totalPage}
                shape="rounded"
                size="medium"
                page={currentPage}
                onChange={handleChangePagination}
              />
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
}

const DataNull = () => {
  return (
    <div className=" w-full flex justify-center mt-10">
      <div>Campaign Not Found</div>
    </div>
  );
};
