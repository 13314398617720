/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useRef } from "react";
import "./styles.css";

import environments from "../../environments";
import { useAppDispatch } from "../../hooks/hooks";
import { getListPrivacy } from "../../redux/tnc/action";

export default function PrivacyPolicy() {
  const dispatch = useAppDispatch();
  const [html, setHTML] = useState({ __html: '' });
  const htmlRef = useRef(null);

  useEffect(() => {
    featchPrivacy();
  }, []);

  const featchPrivacy = useCallback(() => {
    dispatch(
      getListPrivacy({
        path: environments.api.privacypolicy,
        callBack: (data: any) => {
          data.map((item: any) => {
            setHTML({ __html: item.content });
          });
        },
      }),
    );
  }, []);

  return (
    <>
      <div className="h-[280px] bg-gradient-to-r from-[#0097DA] to-[#174479] flex items-center pl-[30px] lg:pl-[153px]">
        <p className="text-[44px] font-extrabold text-white">Privacy Policy</p>
      </div>
      <section className="max-w-6xl mx-auto flex justify-center my-10 px-4 md:px-24">
        <div>
          <div
            ref={htmlRef}
            id="wrap-html"
            className="wrap-html"
            dangerouslySetInnerHTML={html}
          />
        </div>
      </section>
    </>
  );
}
