/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import HeadersCommunity from "./headers";
import IconFollowers from "../../assets/svg/followers.svg";
import IconLike from "../../assets/svg/icon-like.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { OurCommunityCommand } from "../../redux/our-community/reducers";
import { OurCommunityDataModel } from "../../redux/our-community/types";
import { PLATFORM_ICON } from "../home/sectionFour";
import { MiscCommand } from "../../redux/misc/reducers";

import testi1 from '../../assets/Testimoni/erent.jpg';
import testi2 from '../../assets/Testimoni/zahra.webp';
import testi3 from '../../assets/Testimoni/wulan.jpeg';

const DUMMY_INFLUENCERS: OurCommunityDataModel[] = [
  {
    "platformId": '',
    "platformName": 'Instagram',
    "avatar": testi3,
    "city": null,
    "fullname": 'Wulan Purnamasari',
    username: '@wulanpurnamaasari',
    "followers": '1,4k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Instagram',
    "avatar": testi1,
    "city": null,
    "fullname": 'Erent Jenny',
    username: '@its.renxx',
    followers: '81,2k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Instagram',
    "avatar": testi2,
    "city": null,
    "fullname": 'Zahra Putri',
    username: '@zahraputrisurya',
    followers: '535',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Youtube',
    "avatar": testi3,
    "city": null,
    "fullname": 'Wulan Purnamasari',
    username: '@wulanpurnamaasari',
    "followers": '80k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Youtube',
    "avatar": testi1,
    "city": null,
    "fullname": 'Erent Jenny',
    username: '@its.renxx',
    followers: '20k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Youtube',
    "avatar": testi2,
    "city": null,
    "fullname": 'Zahra Putri',
    username: '@zahraputrisurya',
    followers: '600',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Twitter',
    "avatar": testi3,
    "city": null,
    "fullname": 'Wulan Purnamasari',
    username: '@wulanpurnamaasari',
    "followers": '1,2k',
    "engagementRate": ''
  },
  {
    "platformId": '',
    "platformName": 'Twitter',
    "avatar": testi1,
    "city": null,
    "fullname": 'Erent Jenny',
    username: '@its.renxx',
    followers: '60k',
    "engagementRate": ''
  },
];

export default function OurCommunity() {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state) => state.ourCommunity?.list);
  const platformList = useAppSelector((state) => state.misc?.platformList);

  const listTabCommunity = useMemo(() => {
    const list = [];

    list.push({ id: '', name: 'All' });

    for (const i of platformList) {
      list.push({ id: i.id, name: i.name })
    }

    return list;
  }, [platformList]);

  const [active, setActive] = useState("");

  const handleTab = (type: string) => {
    setActive(type);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(MiscCommand.getPlatformList());
  }, []);

  useEffect(() => {
    dispatch(OurCommunityCommand.getContent({
      platform: active
    }));
  }, [active]);

  return (
    <div className="min-h-screen bg-[url('/src/assets/BInus/community/bg-community.svg')] bg-cover md:bg-auto xl:bg-cover bg-no-repeat">
      <div className="max-w-6xl mx-auto px-3">
        <HeadersCommunity />
        {/* <div className="my-14">
          <div className="text-sm font-medium text-center text-gray-500">
            <ul className="flex flex-wrap justify-center gap-3 md:gap-24 -mb-px">
              {listTabCommunity.map((val: any) => {
                return (
                  <li className="mr-2">
                    <div
                      onClick={() => handleTab(val.id)}
                      className={`inline-block cursor-pointer ${
                        val.id === active
                          ? "rounded-t-lg border-b-2 border-[#0097DA] text-[#0097DA]"
                          : "rounded-t-lg border-b-2 text-[#868686] border-transparent hover:text-gray-600 hover:border-gray-300"
                      }`}
                    >
                      {val.name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-6 px-4 lg:px-0 gap-2 lg:gap-5">
          {DUMMY_INFLUENCERS?.map((val: OurCommunityDataModel) => {
            return (
              <div className="cards shadow-none border-0">
                <div className="flex justify-end">
                  <div>
                    <img src={PLATFORM_ICON[val.platformName.toLowerCase()]} alt="icon" />
                  </div>
                </div>
                <div className="flex justify-center">
                  <img
                    className="w-[120px] h-[120px] rounded-full object-cover"
                    src={val.avatar || ''}
                    alt="Rounded avatar"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src="https://cdn-icons-png.flaticon.com/512/147/147142.png";
                    }}
                  />
                </div>
                <div className="my-4 text-center">
                  <p className="title-sm text-[#343536] py-2 font-bold">
                    {val.fullname}
                  </p>
                  {val.username ? <p className="title-sm">{val.username}</p> : null}
                  {val.city ? <p className="title-sm">{val.city}</p> : null}
                  <div className="flex items-center justify-center text-center pt-4">
                    <span className="pr-2">
                      <img src={IconFollowers} alt="followrs" />
                    </span>
                    <p className="text-[#343536]">
                      {val.followers}{" "}
                      <span className="title-sm">Subscribers</span>
                    </p>
                  </div>
                  {val.engagementRate ? <div className="flex items-center justify-center text-center my-2">
                    <span className="pr-2">
                      <img src={IconLike} alt="followrs" />
                    </span>
                    <p className="text-[#343536]">
                      {val.engagementRate}{" "}
                      <span className="title-sm">Engagement Rate</span>
                    </p>
                  </div> : null}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
