import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import createDebounce from 'redux-debounced';
import rootReducer from './rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootAction, RootModel, TDispatch } from './types';
import { MiscCommand } from './misc/reducers';
import { JobCommand } from './job/reducers';
import { NotificationCommand } from './notifications/reducers';

const middlewares = [thunk, createDebounce()]
const store: any = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middlewares)));

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const StoreCommand = {
    misc: MiscCommand,
    notifications: NotificationCommand,
    job: JobCommand
};

export function useStore<TState = RootModel, TSelected = any>(
    selector: (state: TState) => TSelected
): [TSelected | undefined, TDispatch<RootAction>] {
    return [
      useSelector(selector),
      useDispatch<TDispatch<RootAction>>()
    ];
}


export default store;