import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LogoBinus from "../../assets/svg/binus.svg";
import { FiX } from "react-icons/fi";
import Buttons from "../../components/buttons";
import { useAppDispatch } from "../../hooks/hooks";
import { getListTNC } from "../../redux/tnc/action";
import environments from "../../environments";

interface typeProps {
  isOpen: boolean;
  closeModal: () => void;
  handleSubmitJoin: (val: any) => void;
  binusId: string;
  // onSubmit: any;
  // formData?: any;
}

export default function TermsAndConditions(props: typeProps) {
  const dispatch = useAppDispatch();
  const [html, setHTML] = useState({ __html: '' });
  const [isChecked, setIsChecked] = useState(false);
  const { isOpen, closeModal, handleSubmitJoin, binusId } = props;

  const handleSubmit = () => {
    handleSubmitJoin(binusId);
    // onSubmit();
  };

  const handleChaked = (e: any) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    featchTNC();
  }, []);

  const featchTNC = useCallback(() => {
    dispatch(
      getListTNC({
        path: environments.api.termofuse,
        callBack: (data: any) => {
          data.map((item: any) => {
            setHTML({ __html: item.content });
          });
        },
      })
    );
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                >
                  <div>
                    <img src={LogoBinus} alt="logo binus" />
                  </div>
                  <div>
                    <FiX
                      style={{ color: "#0097DA" }}
                      onClick={() => closeModal()}
                      className="cursor-pointer"
                    />
                  </div>
                </Dialog.Title>
                <div className="mt-10 mb-2">
                  <p className="title">Terms & Conditions BINUSFLUENCER</p>
                </div>
                <div className="px-5">
                  <div className="wrap-html" dangerouslySetInnerHTML={html} />
                </div>
                <label className="items-center justify-center text-[#343536] font-normal text-sm">
                  <input
                    className="mr-2 leading-tight"
                    type="checkbox"
                    onChange={handleChaked}
                  />
                  <span className="text-sm text-[#343536]">
                    Saya telah membaca dan menyetujui syarat dan ketentuan yang
                    berlaku.{" "}
                  </span>
                </label>
                <div className="flex justify-center mt-8">
                  <Buttons
                    label="Save"
                    className={`w-[250px] ${
                      !isChecked && "bg-gray-400 hover:bg-gray-400"
                    }`}
                    onClick={handleSubmit}
                    disabled={!isChecked}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
