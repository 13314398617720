/* eslint-disable no-useless-concat */
import { toast } from "react-toastify";
import fetchAxios from "../../helpers/fetchAxios";
import {
  ACCOUNTBANK,
  ADDED_SOCIAL_MEDIA_ACCOUNTS,
  DETAILJOBS,
  DETAIL_EARNING,
  DETAIL_JOB_PERFORMANCE,
  GET_KTP_NPWP,
  LISTACCOUNTBANKALL,
  LISTEARNING,
  LISTJOBDRAFT,
  LISTJOBS,
  LISTMAJOR,
  LISTONGOING,
  LISTPOSTCONTENT,
  LISTSHOWPROFILE,
  LIST_SHOW_PROFILE_SOCIAL_MEDIAS,
  USERS,
} from "./typeAction";
import environments from "../../environments";

type TListTNC = {
  path: string;
  params?: any;
  token: string;
  // callBack?: any;
};

type TListShowProfil = {
  path: string;
  token: string;
  // callBack?: any;
};

type TDetailEarning = {
  path: string;
  token: string;
  // callBack?: any;
};

type TListAccountBank = {
  path: string;
  token: string;
  // callBack?: any;
};
type TListDetailJobs = {
  path: string;
  token: string;
  // callBack?: any;
};
type TListJobDraft = {
  path: string;
  token: string;
};

type TListPostContent = {
  path: string;
  token: string;
};

type TUsers = {
  path: string;
  token: string;
};

type TUploadContent = {
  path: string;
  token: string;
  body: any;
  callBack?: (status: number, data?: any) => void;
};

type TProfileAccount = {
  path: string;
  token: string;
  body: any;
  callBack?: any;
};

type TWithDraw = {
  path: string;
  token: string;
  body: any;
  callBack?: any;
};

export const getlistJob = (props: TListTNC) => {
  const { path, token, params } = props;
  return (dispatch: any) => {
    fetchAxios({
      url:
        path +
        "/allJob" +
        `?onGoing=${params.onGoing}&All=${params.All}&submission=${
          params.submission
        }&completed=${params.completed}&Canceled=${params.Canceled}&offer=${
          params.offer
        }&search=${params.search}${params.Page ? `&Page=${params.Page}` : ""}${
          params.Take ? `&Take=${params.Take}` : ""
        }${params.orderby ? `&orderby=${params.orderby}` : ""}`,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: LISTJOBS,
        payload: res,
      });
    });
  };
};

export const getlistOnGoing = (props: TListTNC) => {
  const { path, token, params } = props;
  return (dispatch: any) => {
    fetchAxios({
      url:
        path +
        "/allJob" +
        `?onGoing=${params.onGoing}&All=${params.All}&submission=${
          params.submission
        }&completed=${params.completed}&Canceled=${params.Canceled}&offer=${
          params.offer
        }&search=${params.search}${params.Page ? `&Page=${params.Page}` : ""}${
          params.Take ? `&Take=${params.Take}` : ""
        }&orderby=${params.orderby}`,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: LISTONGOING,
        payload: res,
      });
    });
  };
};

export const getlistEarning = (props: TListTNC) => {
  const { path, token, params } = props;
  return (dispatch: any) => {
    fetchAxios({
      url:
        path +
        `?&orderby=${params.orderby}&Succeed=${params.Succeed}&Process=${params.Process}&Available=${params.Available}&search=${params.search}`,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: LISTEARNING,
        payload: res,
      });
    });
  };
};

export const postUploadContent = (props: TUploadContent) => {
  const { path, token, body, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "post",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        toast.success("Success");
      } else {
        toast.error("Upload content failed");
      }
      if (callBack) callBack(response.status, response.data.data);
    });
  };
};

export const putUploadScreenshotByContentId = (props: {
  path: string;
  token: string;
  body: { screenshots: string[] };
}) => {
  const { path, token, body } = props;
  return () => {
    fetchAxios({
      url: path,
      method: "put",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        return "ok";
      }
    });
  };
};

export const putUploadContent = (props: TUploadContent) => {
  const { path, token, body, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "put",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        toast.success("Success");
      } else {
        toast.error("Upload content failed");
      }
      if (callBack) callBack(response.status);
    });
  };
};

export const postWithDraw = (props: TWithDraw) => {
  const { path, token, body, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "put",
      token: token,
      body,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Success");
        } else {
          toast.error("Withdraw failed");
        }
      })
      .finally(() => {
        callBack();
      });
  };
};

export const getShowProfil = (props: TListShowProfil) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: LISTSHOWPROFILE,
        payload: res,
      });
    });
  };
};

export const postProfileAccount = (props: TProfileAccount) => {
  const { path, token, body, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "put",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        callBack();
        toast.success("Success");
      } else {
        callBack();
      }
    });
  };
};

export const getKtpNpwp = (props: { id: string; token: string }) => {
  const { id, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: `${environments.api.ktpNpwp}/${id}/ktp-npwp`,
      method: "get",
      token: token,
    }).then((response) => {
      dispatch({
        type: GET_KTP_NPWP,
        payload: response?.data?.data,
      });
    });
  };
};

export const postKTP = (props: {
  id: string;
  token: string;
  body: any;
  callBack?: () => void;
}) => {
  const { id, token, body } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: `${environments.api.ktpNpwp}/${id}/ktp`,
      method: "post",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        toast.success("Success");
      }
    });
  };
};

export const postNPWP = (props: {
  id: string;
  token: string;
  body: any;
  callBack?: () => void;
}) => {
  const { id, token, body } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: `${environments.api.ktpNpwp}/${id}/npwp`,
      method: "post",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        toast.success("Success");
      }
    });
  };
};

export const getMajor = (props: TListShowProfil) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data?.rows;
      dispatch({
        type: LISTMAJOR,
        payload: res,
      });
    });
  };
};

export const postChangePassword = (props: TProfileAccount) => {
  const { path, token, body, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "put",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        callBack();
        toast.success("Success");
      } else {
        callBack();
      }
    });
  };
};

export const getAccountBank = (props: TListAccountBank) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: ACCOUNTBANK,
        payload: res,
      });
    });
  };
};

export const getListAccountBank = (props: TListAccountBank) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data?.rows;
      dispatch({
        type: LISTACCOUNTBANKALL,
        payload: res,
      });
    });
  };
};

export const postBankAccount = (props: TProfileAccount) => {
  const { path, token, body, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "put",
      token: token,
      body,
    }).then((response) => {
      if (response.status === 200) {
        callBack();
        toast.success("Success");
      } else {
        callBack();
      }
    });
  };
};

export const getDetailJobs = (props: TListDetailJobs) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: DETAILJOBS,
        payload: res,
      });
    });
  };
};

export const getDetailJobPerformance = (props: {
  id: string;
  token: string;
}) => {
  const { id, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: environments.api.campaign + `/${id}/job/performance`,
      method: "get",
      token: token ? atob(token) : "",
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: DETAIL_JOB_PERFORMANCE,
        payload: res,
      });
    });
  };
};

export const getListJobDraft = (props: TListJobDraft) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: LISTJOBDRAFT,
        payload: res,
      });
    });
  };
};

export const getListPostContent = (props: TListPostContent) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: LISTPOSTCONTENT,
        payload: res,
      });
    });
  };
};

export const getUsers = (props: TUsers) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: USERS,
        payload: res,
      });
      dispatch({
        type: LIST_SHOW_PROFILE_SOCIAL_MEDIAS,
        payload: res?.[0]?.sosmed || [],
      });
    });
  };
};

export const getDetailEarning = (props: TDetailEarning) => {
  const { path, token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data?.[0];
      dispatch({
        type: DETAIL_EARNING,
        payload: res,
      });
    });
  };
};

export const getAddedSocialMediaAccounts = (props: { token: string }) => {
  const { token } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: environments.api.addedSocialMedia,
      method: "get",
      token: token,
    }).then((response) => {
      const res = response.data?.data;
      dispatch({
        type: ADDED_SOCIAL_MEDIA_ACCOUNTS,
        payload: response.data?.data?.rows || [],
      });
    });
  };
};
