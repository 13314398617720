/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  IFormInputFilter,
  TcheckBox,
  TSidebarCampign,
} from "../../types/campaign";

export default function SideBarCampaign({
  setHitParams,
  hitParams,
  listPlatform,
  initialState,
}: TSidebarCampign) {
  const [checkedItems, setCheckedItems] = useState<string[]>([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputFilter>({
    // resolver: yupResolver(filterRange),
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (e.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    setHitParams({ ...hitParams, Platform: checkedItems.join(",") });
  }, [checkedItems]);

  const handleReset = () => {
    setHitParams(initialState);
    window.location.reload();
  };

  const onSubmit = (data: IFormInputFilter) => {
    let minSplitRp = data.min.replace("Rp", "");
    let minSplitDot = minSplitRp.replace(/\./g, "");
    const minDesimal = parseInt(minSplitDot);
    const minValue = minDesimal ? minDesimal.toString() : "";

    let maxSplitRp = data.max.replace("Rp", "");
    let maxSplitDot = maxSplitRp.replace(/\./g, "");
    const maxDesimal = parseInt(maxSplitDot);
    const maxValue = maxDesimal ? maxDesimal.toString() : "";

    setHitParams({
      ...hitParams,
      MinStartfrom: minValue ? minValue : "",
      MaxStartfrom: maxValue ? maxValue : "",
    });
  };

  const handleOnChange = (event: any) => {
    const { value } = event.target;

    // Hapus semua karakter non-numeric
    const newValue = value.replace(/[^0-9]/g, "");

    // Format nilai dengan separator ribuan
    const formattedValue = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(newValue);

    setValue(event.target.name, formattedValue);
  };

  return (
    <div className="w-full mb-0 px-4 md:w-auto md:px-4 md:mb-20">
      <aside className="w-full lg:w-[275px] md:w-72 mb-8" aria-label="Sidebar">
        <div className="overflow-y-auto py-8 px-8 bg-white rounded-2xl border border-[#E6E6E6]">
          <div className="flex justify-between mb-4">
            <div className="text-[#191919] text-base font-medium">Filter</div>
            <div
              className="text-sm text-[#0097DA] cursor-pointer"
              onClick={handleReset}
            >
              Reset
            </div>
          </div>
          <label className="block text-[#212121] text-sm leading-5 mb-1 font-medium">
            Platform
          </label>
          <div className="my-4">
            {listPlatform?.map(({ name, id }: TcheckBox, index: number) => {
              return (
                <div className="flex items-center mb-4" key={index}>
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name={name}
                    value={id}
                    // checked={checkedItems.get(name)}
                    className="w-4 h-4 text-[#0097DA] bg-[#0097DA]rounded border-gray-300 focus:ring-[#0097DA] "
                    onChange={(e) => handleChange(e, id)}
                  />
                  <label className="ml-2 text-sm font-normal text-[#212121] ">
                    {name}
                  </label>
                </div>
              );
            })}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-between items-center">
              <div>
                <p className=" text-sm font-medium text-[#191919]">
                  Start From
                </p>
              </div>
              <div>
                <button
                  type="submit"
                  className=" text-sm font-normal text-[#0097DA]"
                >
                  Apply
                </button>
              </div>
            </div>
            <div>
              <input
                {...register("min")}
                className="mt-3 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-0 block w-full p-2.5 "
                placeholder="Min"
                onChange={handleOnChange}
              />
              <p className="text-sm text-[#e11d48]">{errors.min?.message}</p>
              <input
                {...register("max")}
                className="mt-2 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-0 block w-full p-2.5 "
                placeholder="Max"
                onChange={handleOnChange}
              />
              <p className="text-sm text-[#e11d48]">{errors.max?.message}</p>
            </div>
          </form>
        </div>
      </aside>
    </div>
  );
}
