import environments from "../../environments"
import fetchAxios from "../../helpers/fetchAxios"
import {
  NotificationAction,
  NotificationActionType,
  NotificationModel,
} from "./types"

const initialState: NotificationModel = {
  list: [],
}

const NotificationReducer = (
  state: NotificationModel = initialState,
  action: NotificationAction
) => {
  switch (action.type) {
    case NotificationActionType.GET_LIST_NOTIFICATIONS:
      return { ...state, list: action.payload }

    default:
      return { ...state }
  }
}

const NotificationCommand = {
  getNotifications: ({ token }: { token: string }) => {
    return (dispatch: any) => {
      fetchAxios({
        url: environments.api.notification,
        method: "get",
        token: token,
        params: {
          take: 9999,
          sortDirection: true,
          orderby: "createdAt",
        },
      }).then((response) => {
        const res = response.data?.data?.rows
        dispatch({
          type: NotificationActionType.GET_LIST_NOTIFICATIONS,
          payload: res,
        })
      })
    }
  },
  putReadNotificationById: ({ id, token }: { id: string; token: string }) => {
    return (dispatch: any) => {
      return fetchAxios({
        url: `${environments.api.notification}/${id}/read`,
        method: "put",
        token,
      }).then((response) => {
        if (response.status === 200) {
          dispatch(NotificationCommand.getNotifications({ token }))
        }
      })
    }
  },
  putReadAllNotification: ({ token }: { token: string }) => {
    return (dispatch: any) => {
      return fetchAxios({
        url: `${environments.api.notification}/readAll`,
        method: "put",
        token,
      }).then((response) => {
        if (response.status === 200) {
          dispatch(NotificationCommand.getNotifications({ token }))
        }
      })
    }
  },
}

export { NotificationReducer, NotificationCommand }
