import { combineReducers } from 'redux'
import campaignReducer from './campaign/reducer';
import profileReducer from './profile/reducer';
import countReducer from './reducers';
import masterReducer from './reducersMaster';
import tncReducer from './tnc/reducer';
import { NotificationReducer } from './notifications/reducers';
import { OurCommunityReducer } from './our-community/reducers';
import { MiscReducer } from './misc/reducers';
import { JobReducer } from './job/reducers';

const rootReducer = combineReducers({
    count: countReducer,
    master: masterReducer,
    campaign: campaignReducer,
    tncReducer: tncReducer,
    profileReducer: profileReducer,
    notifications: NotificationReducer,
    ourCommunity: OurCommunityReducer,
    misc: MiscReducer,
    job: JobReducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer;