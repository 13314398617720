export interface ListPostDataModel {
    "id": string
    "message": string
    "full_picture": string
    "shares": null
}

export interface JobModel {
    listPost: ListPostDataModel[]
}

export enum JobActionType {
    GET_LIST_POST = "GET_LIST_POST",
}

export type JobAction = {
    payload: ListPostDataModel[];
    type: JobActionType.GET_LIST_POST;
};
