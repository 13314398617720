export interface OurCommunityDataModel {
    "platformId": string
    "platformName": string
    "avatar": string | null
    "city": string | null
    "fullname": string | null
    "followers": string
    "engagementRate": string
    username?: string
  }
  
  export interface OurCommunityModel {
      list: OurCommunityDataModel[]
  }
  
  export enum OurCommunityActionType {
      GET_LIST_COMMUNITY = "GET_LIST_COMMUNITY"
  };
  
  export type OurCommunityAction = {
      payload: OurCommunityDataModel[],
      type: OurCommunityActionType.GET_LIST_COMMUNITY
  }
  