/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import IconInformation from "../../assets/svg/ant-design_info-circle-filled.svg";
import ToolTip from "../../components/tooltip";
import Buttons from "../../components/buttons";
import dayjs from "dayjs";
import { formatRp } from "../../helpers";
import platForm from "../../components/platForm/platForm";

interface TDetailRight {
  isLoading: boolean
  isOpen: () => void;
  data: any;
  handleAcceptOffer: () => void;
  handleDeclineOffer: () => void;
}

export default function DetailRight({
  isLoading,
  isOpen,
  data,
  handleAcceptOffer,
  handleDeclineOffer,
}: TDetailRight) {
  const [showBtn1, setShowBtn1] = useState(false),
    [showBtn2, setShowBtn2] = useState(false);

  useEffect(() => {
    let btnType1 = false;
    let btnType2 = false;

    if (data.isInvited === true) {
      if (data.influencerStatus === 0) {
        btnType1 = false;
        btnType2 = true;
      }
      if (data.influencerStatus === 1) {
        btnType1 = false;
        btnType2 = false;
      }
      if (data.influencerStatus === -1) {
        btnType1 = true;
        btnType2 = false;
      }
      if (data.influencerStatus === -2) {
        btnType1 = true;
        btnType2 = false;
      }
    }

    if (data.isInvited === false) {
      if (data.influencerStatus === 0) {
        btnType1 = false;
        btnType2 = false;
      }
      if (data.influencerStatus === 1) {
        btnType1 = false;
        btnType2 = false;
      }
      if (data.influencerStatus === -1) {
        btnType1 = true;
        btnType2 = false;
      }
      if (data.influencerStatus === -2) {
        btnType1 = true;
        btnType2 = false;
      }
    }

    if (data.influencerStatus === null || data.isInvited === null) {
      btnType1 = true
      btnType2 = false
    }
    
    setShowBtn1(btnType1);
    setShowBtn2(btnType2);
  }, [data]);

  // BUTTON TIPE 1
  const JoinButton = () => {
    return (
      <div className="flex flex-wrap lg:flex-nowrap justify-center flex-w gap-2 pt-7">
        <Buttons isLoading={isLoading} label="Join Campaign" onClick={isOpen} />
      </div>
    );
  };

  // BUTTON TIPE 2
  const AcceptDeclineButton = () => {
    return (
      <div className="flex flex-wrap lg:flex-nowrap justify-center flex-w gap-2 pt-7">
        <Buttons
         isLoading={isLoading}
          label="Decline"
          className="w-[176px]"
          variant="outline"
          onClick={handleDeclineOffer}
        />
        <Buttons
         isLoading={isLoading}
          label="Accept"
          className="w-[176px]"
          onClick={handleAcceptOffer}
        />
      </div>
    );
  };

  return (
    <div className="block p-5 bg-white border border-gray-200 rounded-2xl shadow-md mb-3 h-fit">
      <div>
        <div className="flex flex-wrap sm:flex-col gap-x-5 pb-7">
          <section className="mb-5">
            <p className="text-md text-[#868686] mb-2">{data.brandName}</p>
            <p className="text-md text-[#212121] font-semibold mb-2">
              {data.title}
            </p>
          </section>
          <section className="">
            <p className="text-md text-[#868686] mb-2">Deadline Post</p>
            <p className="text-md text-[#212121] font-semibold mb-2">
              {dayjs(data.startCampaign).format("DD MMM YYYY")}
            </p>
          </section>
        </div>
        <hr className="border-t-2 border-dashed border-[#D5D5D5]" />
        <section className="pt-7">
          <p className="text-md text-[#868686] mb-2">Platform</p>
          {data?.campaignPlatform?.map((item: any, idx: number) => {
            return (
              <div
                className="flex items-center"
                key={`${item.platformId}-${idx}`}
              >
                {platForm(item.platformName)}
                <div>
                  <p className="text-md text-[#212121] font-semibold mb-2">
                    {item.platformName}
                  </p>
                </div>
              </div>
            );
          })}
        </section>
        <section className="pt-3.5">
          <div className="flex items-center">
            <div>
              <p className="text-md text-[#868686]">Start From</p>
            </div>
            <div className="pl-2">
              <ToolTip tooltip="*Income does not include PPN 10%">
                <img src={IconInformation} alt="icons" />
              </ToolTip>
            </div>
          </div>
          <p className="text-md text-[#0097DA] font-semibold pt-3.5">
            Rp{" "}
            {data.campaignInfluencerCategory[0]?.rateCard?.price
              ? formatRp(data.campaignInfluencerCategory[0]?.rateCard?.price)
              : "-"}
          </p>
        </section>
        {showBtn1 ? <JoinButton /> : null}
        {showBtn2 ? <AcceptDeclineButton /> : null}
      </div>
    </div>
  );
}
