
import BinusASO from "../../assets/BInus/01.svg";
import BinusGraduateProgram from "../../assets/BInus/02.svg";
import BinusUniversity from "../../assets/BInus/03.svg";
import BinusBusinessSchool from "../../assets/BInus/04.svg";
import BinusOnline from "../../assets/BInus/05.svg";
import BinusInternational from "../../assets/BInus/06.svg";

const listBrand = [
  {
    icons: BinusUniversity
  },
  {
    icons: BinusInternational
  },
  {
    icons: BinusASO
  },
  {
    icons: BinusOnline
  },
  {
    icons: BinusBusinessSchool
  },
  {
    icons: BinusGraduateProgram
  }
];

export default function SectionFive() {
  return (
    <div className="min-h-[659px] bg-white">
      <div className="max-w-6xl mx-auto pt-16 lg:pt-[110px] px-4 xl:px-0">
        <div className="flex flex-wrap justify-center lg:justify-start items-center gap-20">
          <div className="w-[392px]">
            <p className="text-[36px] font-bold lg:text-[44px]">
              Get a Chance to Work With{" "}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
                Brands
              </span>
            </p>
          </div>
          <div className="w-[466px]">
            <p className="title-sm text-[#212121]">
              Selain mendapatkan passive income dan reward, BINUSFLUENCER juga memiliki kesempatan untuk bekerjasama dengan brand-brand BINA NUSANTARA Group.
            </p>
          </div>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-16 py-8">
          {listBrand.map((data: any) => {
            return (
              <div className="max-w-[320px]">
                <img src={data.icons} alt="icon" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
