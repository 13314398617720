import React from "react";
import IconAdvan from "../../assets/BInus/brands/company/advan-330x160.svg";
import IconKaleyo from "../../assets/BInus/brands/company/bebek-kaleyo-330x160.svg";
import IconCimory from "../../assets/BInus/brands/company/cimory-330x160.svg";
import IconKolo from "../../assets/BInus/brands/company/kolo-330x160.svg";
import IconLimu from "../../assets/BInus/brands/company/limu-330x160.svg";
import IconManzone from "../../assets/BInus/brands/company/manzone-330x160.svg";
import IconSocial from "../../assets/BInus/brands/company/socialights-330x160.svg";
import IconTraveloka from "../../assets/BInus/brands/company/travelok-330x160.svg";
import IconTrueID from "../../assets/BInus/brands/company/true-id-330x160.svg";
import IconSmartfren from "../../assets/BInus/brands/company/smartfren-330x160.svg";

export const listCommpany = [
  {
    icon: IconAdvan,
  },
  {
    icon: IconKaleyo,
  },
  {
    icon: IconCimory,
  },
  {
    icon: IconKolo,
  },
  {
    icon: IconLimu,
  },
  {
    icon: IconManzone,
  },
  {
    icon: IconSocial,
  },
  {
    icon: IconTraveloka,
  },
  {
    icon: IconTrueID,
  },
  {
    icon: IconSmartfren,
  },
];

export default function SectionTree() {
  return (
    <div className="py-10">
      <div className="flex justify-center px-4 text-center">
        <p className="text-[36px] font-bold lg:text-[44px]">
          Brands That Trust Our{" "}
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#0097DA] to-[#174479] ">
            Service
          </span>
        </p>
      </div>
      <div className="max-w-6xl mx-auto px-3 my-16">
        <div className="flex flex-wrap gap-10 justify-center">
          {listCommpany.map((val: any) => {
            return (
              <div className="w-[133px]">
                <img src={val.icon} alt="icons" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
