export interface PlatformListDataModel {
    id: string;
    name: string;
}

export interface CampusAreaListDataModel {
    id: string;
    code: string;
    name: string;
}

export interface MajorListDataModel {
    facultyId: string;
    majorCode: string;
    name: string;
}

export interface MiscModel {
    campusArea: CampusAreaListDataModel[];
    platformList: PlatformListDataModel[];
    majorList: MajorListDataModel[]
}

export enum MiscActionType {
    GET_LIST_CAMPUS_AREA = "GET_LIST_CAMPUS_AREA",
    GET_LIST_PLATFORM = "GET_LIST_PLATFORM",
    GET_LIST_MAJOR = "GET_LIST_MAJOR",
}

export type MiscAction = {
    payload: PlatformListDataModel[];
    type: MiscActionType.GET_LIST_PLATFORM;
} | {
    payload: CampusAreaListDataModel[];
    type: MiscActionType.GET_LIST_CAMPUS_AREA
} | {
    payload: MajorListDataModel[];
    type: MiscActionType.GET_LIST_MAJOR
};
