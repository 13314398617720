/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";

import { listMenu } from "../../utilities/constants";
import LogoBinus from "../../assets/svg/binus.svg";
import { NavLink, useLocation } from "react-router-dom";
import {
  AUTH_REGISTER,
  AUTH_SIGNIN,
  CAMPAIGN,
  FORBINUSFLUENCER,
  FOR_BRAND,
  HOME,
  OUR_COMMUNITY,
  PROFILE_DASHBOARD,
} from "../../routes/listRoute";
import Cookies from "js-cookie";
import { IoCloseOutline } from "react-icons/io5";
import Search from "./search";
import { useSelector } from "react-redux";
import { imageError } from "../../helpers/brokenImg";
import { BiBell } from "react-icons/bi";
import { NotificationOffcanvas } from "../notifications";
import { useAppSelector } from "../../hooks/hooks";
import { NotificationDataModel } from "../../redux/notifications/types";

export default function NavBar() {
  const location = useLocation();
  const users = useSelector((state: any) => state.profileReducer.users);
  const notifStore = useAppSelector((state) => state.notifications.list);
  const hasUnread = useMemo(() => notifStore?.some((o: NotificationDataModel) => o.readAt === null), [notifStore]);

  let tokenBase64 = Cookies.get("tokenY");
  const [isToggleMobile, setIsToggleMobile] = useState(false);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [listMenus] = useState([
    {
      label: "Home",
      link: HOME,
      active: false,
      isHidden: false,
    },
    {
      label: "For Binusfluencer",
      link: FORBINUSFLUENCER,
      active: false,
      isHidden: false,
    },
    {
      label: "For Brands",
      link: FOR_BRAND,
      active: false,
      isHidden: true,
    },
    {
      label: "Campaign",
      link: CAMPAIGN,
      active: false,
      isHidden: tokenBase64 ? false : true,
    },
    {
      label: "Our Community",
      link: OUR_COMMUNITY,
      active: false,
      isHidden: false,
    },
  ]);

  const [showNotif, setShowNotif] = useState(false);
  const toggleShowNotif = () => setShowNotif(!showNotif);

  const listMenuReloaded = useMemo(() => listMenu(tokenBase64 || ''), [tokenBase64])

  useEffect(() => {
    if (isToggleMobile) {
      setIsToggleMobile(false);
    }
  }, [location]);

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center py-2.5 px-4 xl:px-0">
          <div className="flex gap-24">
            {/* logo */}
            <div>
              <a href={HOME} className="flex items-center py-4 px-4 sm:px-2">
                <img src={LogoBinus} alt="Logo" />
              </a>
            </div>
            {/* menu or search */}
            <div className="hidden xl:flex items-center space-x-8 ">
              {isShowSearch ? (
                <Search />
              ) : (
                listMenus.map((val: any, i: any) => {
                  return (
                    <NavLink
                      key={i}
                      to={val.link}
                      className={({ isActive }) =>
                        isActive ? "active-menu" : "title-sm"
                      }
                      hidden={val.isHidden}
                    >
                      {val.label}
                    </NavLink>
                  );
                })
              )}
              {tokenBase64 !== undefined ? (
                <div className="py-4 px-2 title-sm ">
                  {!isShowSearch ? (
                    <FiSearch
                      className="w-5 h-5 text-black cursor-pointer"
                      onClick={() => setIsShowSearch(!isShowSearch)}
                    />
                  ) : (
                    <IoCloseOutline
                      className="w-6 h-6 text-black cursor-pointer"
                      onClick={() => setIsShowSearch(!isShowSearch)}
                    />
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className="hidden xl:flex items-center space-x-1 gap-5">
            <div style={{ borderLeft: "1px solid #D3D4D5", height: "20px" }} />
            {tokenBase64 !== undefined ? (
              <>
                <div className="relative cursor-pointer" onClick={toggleShowNotif}>
                  <BiBell size={18} />
                  {hasUnread ? <div className="absolute bg-red-600 rounded border border-white w-[6px] h-[6px] top-0 right-[2px]" /> : null}
                </div>
                <div className="w-[108px]">
                  <p className="title-sm text-[#343536] font-semibold truncate">
                    {`Hi, ${users[0]?.firstName ? users[0]?.firstName : "-"}`}
                  </p>
                </div>
                <a href={PROFILE_DASHBOARD}>
                  <img
                    className="w-[37px] h-[37px] rounded-full ring-4 ring-[#E5F5FB] object-cover"
                    src={
                      users[0]?.profile
                        ? users[0]?.profile
                        : "https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png"
                    }
                    alt="Rounded avatar"
                    onError={(e) => imageError(e)}
                  />
                </a>
              </>
            ) : (
              <>
                <NavLink
                  to={AUTH_SIGNIN}
                  className="py-2 title-sm text-[#0097DA]"
                >
                  Sign In
                </NavLink>
                <NavLink to={AUTH_REGISTER} className="btn-navbar">
                  Register
                </NavLink>
              </>
            )}
          </div>
          {/* button responsive */}
          <div className="xl:hidden flex items-center px-4 gap-4">
          {tokenBase64 !== undefined ? (
            <div className="relative cursor-pointer" onClick={toggleShowNotif}>
              <BiBell size={18} />
              {hasUnread ? <div className="absolute bg-red-600 rounded border border-white w-[6px] h-[6px] top-0 right-[2px]" /> : null}
            </div>
          ) : null}
            <button
              className="outline-none mobile-menu-button"
              onClick={() => setIsToggleMobile(!isToggleMobile)}
            >
              <svg
                className=" w-6 h-6 text-gray-500 "
                x-show="!showMenu"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div
        className={`${
          isToggleMobile ? "absolute bg-white w-full flex z-10 pb-6" : "hidden"
        } `}
      >
        <ul className="w-full">
          {listMenuReloaded.map((value: any, i: any) => {
            if (value.isHidden) return null;
            
            return (
              <li className="" key={i}>
                <NavLink to={value.link} className="block px-2 py-4 title-sm">
                  {value.label}
                </NavLink>
              </li>
            );
          })}
          {tokenBase64 !== undefined ? (
            <a className="flex items-center gap-4" href={PROFILE_DASHBOARD}>
              <img
                className="ml-2 w-[37px] h-[37px] rounded-full ring-4 ring-[#E5F5FB] object-cover"
                src={
                  users[0]?.profile
                    ? users[0]?.profile
                    : "https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png"
                }
                alt="Rounded avatar"
                onError={(e) => imageError(e)}
              />
              <div className="title-sm text-[#343536] font-semibold truncate">
                {`Hi, ${users[0]?.firstName ? users[0]?.firstName : "-"}`}
              </div>
            </a>
          ) : (
            <>
              <li className="">
                <NavLink to={AUTH_SIGNIN} className="block px-2 py-4 title-sm">
                  Sign In
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  to={AUTH_REGISTER}
                  className="block px-2 py-4 title-sm"
                >
                  Register
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </div>
      <NotificationOffcanvas show={showNotif} toggleShow={toggleShowNotif} />
    </nav>
  );
}
