import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../redux/store"
import { useLocation } from "react-router-dom"
import { useEffect } from "react"

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

type WindowWithDataLayer = Window & {
  gtag: Function
}

declare const window: WindowWithDataLayer

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    console.log("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location])
}
