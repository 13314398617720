import environments from "../../environments";
import fetchAxios from "../../helpers/fetchAxios";
import { JobAction, JobActionType, JobModel } from "./types";

const initialState: JobModel = {
  listPost: [],
};

const JobReducer = (state: JobModel = initialState, action: JobAction) => {
  switch (action.type) {
    case JobActionType.GET_LIST_POST:
      return { ...state, listPost: action.payload };

    default:
      return { ...state };
  }
};

const JobCommand = {
  getJobListPost: (
    platformId: string,
    accountId: string,
    token: string,
    type?: number
  ) => {
    return (dispatch: any) => {
      fetchAxios({
        url: `${
          environments.api.campaign
        }/ListPost?Take=100&Page=1&platformId=${platformId}&accountId=${accountId}&isStory=${
          type === 2
        }`,
        method: "get",
        token: token,
      }).then((response) => {
        const res = response.data?.data?.rows;
        dispatch({
          type: JobActionType.GET_LIST_POST,
          payload: res,
        });
      });
    };
  },
  postSelectedPostContent: (
    campaignId: string,
    payload: unknown,
    token: string
  ) => {
    return fetchAxios({
      url: `${environments.api.campaign}/${campaignId}/job/SelectedPostContent`,
      method: "post",
      body: payload,
      token: token,
    })
      .then((response) => {
        if (response.status === 200) {
          return "ok";
        }
      })
      .catch(() => {
        return "err";
      });
  },
};

export { JobReducer, JobCommand };
