import React, { useCallback, useEffect, useState } from "react";
import environments from "../../environments";
import { useAppDispatch } from "../../hooks/hooks";
import { getListTNC } from "../../redux/tnc/action";
import "./styles.css";

export default function TandC() {
  const dispatch = useAppDispatch();
  const [html, setHTML] = useState({ __html: '' });

  useEffect(() => {
    featchTNC();
  }, []);

  const featchTNC = useCallback(() => {
    dispatch(
      getListTNC({
        path: environments.api.termofuse,
        callBack: (data: any) => {
          data.map((item: any) => {
            setHTML({ __html: item.content });
          });
        },
      })
    );
  }, []);

  return (
    <>
      <div className="h-[280px] bg-gradient-to-r from-[#0097DA] to-[#174479] flex items-center pl-[30px] lg:pl-[153px]">
        <p className="text-[44px] font-extrabold text-white">
          Terms & Conditions
        </p>
      </div>
      <section className="max-w-6xl mx-auto flex justify-center my-10 px-4 md:px-24">
        <div className="wrap-html" dangerouslySetInnerHTML={html} />
      </section>
    </>
  );
}
