import { LISTPRIVACY, LISTTNC } from "./typeAction";

type TTNC = {
  loading: boolean,
  data: any
  dataPrivacy : any
};
type TAction = {
  type: string;
  payload?: any;
};

const initialState: TTNC = {
  loading: false,
  data: [],
  dataPrivacy: []
};

const tncReducer = (state: TTNC = initialState, action: TAction) => {
  switch (action.type) {
    case LISTTNC:
      return {
        ...state,
        loading: true,
        data: action.payload
      }
    case LISTPRIVACY:
      return {
        ...state,
        dataPrivacy: action.payload
      }

    default:
      return state;
  }
};

export default tncReducer;
