/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import IconsCooperation from "../../assets/BInus/brands/undraw_happy_announcement_re_tsm0 1.svg";
import { IFormInputsPersonalDetail } from "../../types";
import { PRIVACYPOLICY, TANDC } from "../../routes/listRoute";
import Buttons from "../../components/buttons";
import Modals from "../../components/modals";
import HeadersAuth from "../../components/navBars/headersAuth";

export default function StartProject() {
  const [isOpen, setIsOpen] = useState(false);
  const {
    register,
    formState: { errors },
  } = useForm<IFormInputsPersonalDetail>({
    // resolver: yupResolver(schemaRegisterPersonal),
  });

  return (
    <div className="bg-cover bg-[url('/src/assets/svg/bg-binus.png')] bg-center xs:bg-contain">
      <HeadersAuth type={2} />
      <div className="flex min-h-full items-center justify-center h-screen py-12 px-4 sm:px-6 lg:px-8">
        <div className="cards w-[816px] p-10">
          <div className="mb-4">
            <div>
              <p className="font-bold text-2xl text-[#343536]">
                Personal Details
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block text-[#343536] text-sm leading-5 mb-1">
                Full Name <span className=" text-red-600">*</span>
              </label>
              <input
                {...register("fullname")}
                className="input focus:outline-[#0097DA] focus:border-[#0097DA]"
                type="text"
              />
              <p className="text-sm text-[#e11d48]">
                {errors.fullname?.message}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-[#343536] text-sm leading-5 mb-1 ">
                Phone <span className=" text-red-600">*</span>
              </label>
              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-gray-900 rounded-l-md border border-r-0 border-gray-300 bg-white">
                  +62
                </span>
                <input
                  {...register("phone")}
                  className="rounded-none rounded-r-lg bg-white border text-gray-900 focus:outline-[#0097DA] focus:border-[#0097DA] block flex-1 min-w-0 w-full text-sm p-2.5"
                  type="text"
                />
              </div>
              <p className="text-sm text-[#e11d48]">{errors.phone?.message}</p>
            </div>
            <div className="mb-4">
              <label className="block text-[#343536] text-sm leading-5 mb-1">
                Email Address <span className=" text-red-600">*</span>
              </label>
              <input
                {...register("email")}
                className="input focus:outline-[#0097DA] focus:border-[#0097DA]"
                type="text"
              />
              <p className="text-sm text-[#e11d48]">{errors.email?.message}</p>
            </div>
            <div className="mb-4">
              <label className="block text-[#343536] text-sm leading-5 mb-1">
                Company name <span className=" text-red-600">*</span>
              </label>
              <input
                {...register("email")}
                className="input focus:outline-[#0097DA] focus:border-[#0097DA]"
                type="text"
              />
              <p className="text-sm text-[#e11d48]">{errors.email?.message}</p>
            </div>
          </div>
          <div>
            <label className="items-center justify-center text-[#343536] font-normal text-sm">
              <input className="mr-2 leading-tight" type="checkbox" />
              <span className="text-sm">I read and accept the entire </span>
              <a
                className="text-sm text-[#0097DA] cursor-pointer underline"
                href={TANDC}
                target="_blank"
              >
                terms and conditions
              </a>
              <span className="text-sm"> and </span>
              <a
                href={PRIVACYPOLICY}
                target="_blank"
                className="text-sm text-[#0097DA] cursor-pointer underline"
              >
                {" "}
                privacy policy
              </a>
            </label>
          </div>
          <div className="flex items-center justify-center mt-8">
            <Buttons
              label="Send"
              className="w-[344px]"
              onClick={() => setIsOpen(!isOpen)}
            />
          </div>
        </div>
      </div>
      <Modals
        isOpen={isOpen}
        closeModal={() => setIsOpen(!isOpen)}
        className="w-[454px]"
      >
        <div className="flex justify-center">
          <img src={IconsCooperation} alt="icon" />
        </div>
        <div className="flex justify-center mt-4 text-center my-6">
          <div>
            <p className="text-base text-[#212121] font-semibold">
              Cooperation Application Sent
            </p>
            <p className="text-sm text-[#343536] py-5">
              Wait 1x24 binusfluencer admin will contact you
            </p>
            <div className="mt-4">
              <Buttons label="Ok" onClick={() => setIsOpen(!isOpen)} />
            </div>
          </div>
        </div>
      </Modals>
    </div>
  );
}
