import React from "react";
import { FiChevronLeft } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import LogoBinus from "../../assets/svg/binus.svg";
import { AUTH_SIGNIN, HOME } from "../../routes/listRoute";

export default function HeadersAuth(props: any) {
  const { type } = props;
  let component = "";

  switch (type) {
    case 1:
      component = "Back to Sign In";
      break;
    case 2:
      component = "Back";
      break;
    default:
      component = "Back to home";
      break;
  }

  return (
    <div className="flex md:justify-between items-center py-7 px-4 max-w-6xl mx-auto">
      <NavLink to={HOME} className="hidden md:block">
        <img src={LogoBinus} alt="logo binus" />
      </NavLink>
      <div className="flex items-center cursor-pointer">
        <span className="pr-1">
          <FiChevronLeft style={{ color: "#0097DA" }} />
        </span>
        <NavLink to={type === 1 ? AUTH_SIGNIN : HOME}>
          <p className="text-[#0097DA]">{component}</p>
        </NavLink>
      </div>
    </div>
  );
}
