import { useEffect, useRef, useState } from 'react';
import Pic1 from './assets/1.png';
import Pic2 from './assets/3.png';
import Pic3 from './assets/4.png';
import Pic4 from './assets/5.png';
import Pic5 from './assets/6.png';
import Pic6 from './assets/7.png';

import "./styles.css";

export default function HowToRegister() {
  const ref1 = useRef<HTMLDivElement | null>(null);
  const ref2 = useRef<HTMLDivElement | null>(null);
  const ref3 = useRef<HTMLDivElement | null>(null);
  const ref4 = useRef<HTMLDivElement | null>(null);
  const ref5 = useRef<HTMLDivElement | null>(null);
  const ref6 = useRef<HTMLDivElement | null>(null);

  const [currentVis, setCurrentVis] = useState(0);

  const checkView = () => {
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    const rect1 = ref1.current?.getBoundingClientRect();
    const rect2 = ref2.current?.getBoundingClientRect();
    const rect3 = ref3.current?.getBoundingClientRect();
    const rect4 = ref4.current?.getBoundingClientRect();
    const rect5 = ref5.current?.getBoundingClientRect();
    const rect6 = ref6.current?.getBoundingClientRect();

    const rect1View = rect1 && ((rect1.bottom > 0 && rect1.bottom < viewHeight) && rect1.top - viewHeight <= 0)
    const rect2View = rect2 && ((rect2.bottom > 0 && rect2.bottom < viewHeight) && rect2.top - viewHeight <= 0)
    const rect3View = rect3 && ((rect3.bottom > 0 && rect3.bottom < viewHeight) && rect3.top - viewHeight <= 0)
    const rect4View = rect4 && ((rect4.bottom > 0 && rect4.bottom < viewHeight) && rect4.top - viewHeight <= 0)
    const rect5View = rect5 && ((rect5.bottom > 0 && rect5.bottom < viewHeight) && rect5.top - viewHeight <= 0)
    const rect6View = rect6 && ((rect6.bottom > 0 && rect6.bottom < viewHeight) && rect6.top - viewHeight <= 0)

    if (rect1View) {
      setCurrentVis(1);
    }
    if (rect2View) {
      setCurrentVis(2);
    }
    if (rect3View) {
      setCurrentVis(3);
    }
    if (rect4View) {
      setCurrentVis(4);
    }
    if (rect5View) {
      setCurrentVis(5);
    }
    if (rect6View) {
      setCurrentVis(6);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkView);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    return () => {
      window.removeEventListener("scroll", checkView);
    }
  }, []);

  return (
    <>
      <div className="h-[280px] bg-gradient-to-r from-[#0097DA] to-[#174479] flex items-center pl-[30px] lg:pl-[153px]">
        <p className="text-[44px] font-extrabold text-white">
          How to Join Us
        </p>
      </div>
      <section className="pl-[30px] lg:pl-[153px] max-w-10xl mx-auto flex justify-center my-10 px-4 md:px-24">
        <div className="wrap-html">

          <div className="grid grid-cols-2 gap-8">
            <div className="w-3/4">

              <div className="how-step" ref={ref1}>
                <div className="my-auto">
                  <div className="how-text font-bold mb-4">Step 1</div>
                  <div className="how-text">Kunjungi halaman utama BINUSFLUENCER dan klik Registrasi. Lalu, lengkapi Personal Details yang diminta.</div>
                </div>
              </div>

              <div className="how-step" ref={ref2}>
                <div className="my-auto">
                  <div className="how-text font-bold mb-4">Step 2</div>
                  <div className="how-text">
                    Pilih Kategori BINUSFLUENCER sesuai dengan bidangmu.
                  </div>
                  <div className="italic how-text">
                    (Kategori bisa pilih lebih dari satu)
                  </div>
                </div>
              </div>

              <div className="how-step" ref={ref3}>
                <div className="my-auto">
                  <div className="how-text font-bold mb-4">Step 3</div>
                  <div className="how-text">
                    Sambungkan link-link Social Media pribadimu dengan akun BINUSFLUENCER. 
                  </div>
                  <div className="italic how-text">
                    (Pastikan akun tidak di Private)
                  </div>
                </div>
              </div>

              <div className="how-step" ref={ref4}>
                <div className="my-auto">
                  <div className="how-text font-bold mb-4">Step 4</div>
                  <div className="how-text">
                    Upload foto (max. 2 mb). Lalu, klik Next Step.
                  </div>
                </div>
              </div>

              <div className="how-step" ref={ref5}>
                <div className="my-auto">
                  <div className="how-text font-bold mb-4">Step 5</div>
                  <div className="how-text">
                    Lakukan konfirmasi persyaratan dengan mencentang dua kolom yang diminta. Lalu, klik Save.
                  </div>
                </div>
              </div>

              <div className="how-step" ref={ref6}>
                <div className="my-auto">
                  <div className="how-text font-bold mb-4">Step 6</div>
                  <div className="how-text">
                    Setelah pendaftaran berhasil, akun Anda akan ditinjau terlebih dahulu oleh Admin dan akan dikabarkan melalui email.
                  </div>
                </div>
              </div>

            </div>

            <div className="sticky h-[80vh] top-0">
              <div className="how-step-img">
                <img className={`mt-4 mb-8 flex justify-center mx-auto ${currentVis === 1 ? 'opacity-100' : 'opacity-0'}`} src={Pic1} loading="lazy" />
                <img className={`mt-4 mb-8 flex justify-center mx-auto ${currentVis === 2 ? 'opacity-100' : 'opacity-0'}`} src={Pic2} loading="lazy" />
                <img className={`mt-4 mb-8 flex justify-center mx-auto ${currentVis === 3 ? 'opacity-100' : 'opacity-0'}`} src={Pic3} loading="lazy" />
                <img className={`mt-4 mb-8 flex justify-center mx-auto ${currentVis === 4 ? 'opacity-100' : 'opacity-0'}`} src={Pic4} loading="lazy" />
                <img className={`mt-4 mb-8 flex justify-center mx-auto ${currentVis === 5 ? 'opacity-100' : 'opacity-0'}`} src={Pic5} loading="lazy" />
                <img className={`mt-4 mb-8 flex justify-center mx-auto ${currentVis === 6 ? 'opacity-100' : 'opacity-0'}`} src={Pic6} loading="lazy" />
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  );
}
