import React from "react";
import LogoBinus from "../../assets/svg/binus.svg";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { HOME } from "../../routes/listRoute";

export default function NotFound() {
  return (
    <div className="flex min-h-full items-center justify-center h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div>
        <div className="flex justify-center items-center mb-6">
          <img src={LogoBinus} alt="logo binus" className="text-center" />
        </div>
        <div className="mb-2">
          <p className="text-[#0C51DC] text-center text-base">404</p>
        </div>
        <div className="mb-2">
          <p className="text-5xl font-bold">Page not found</p>
        </div>
        <div className="mb-6">
          <p className="text-[#9ca3af]">
            Sorry, we couldn't find the page you're looking for
          </p>
        </div>
        <div className="flex justify-center items-center">
          <Link to={HOME}>
            <p className="text-[#0C51DC]">Go back home</p>
          </Link>
          <span className="pl-2">
            <FiArrowRight style={{ color: "#0C51DC" }} />
          </span>
        </div>
      </div>
    </div>
  );
}
