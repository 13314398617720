import environments from "../../environments";
import fetchAxios from "../../helpers/fetchAxios";
import { MiscAction, MiscActionType, MiscModel } from "./types";

const initialState: MiscModel = {
  campusArea: [],
  platformList: [],
  majorList: [],
};

const MiscReducer = (
  state: MiscModel = initialState,
  action: MiscAction
) => {
  switch (action.type) {
    case MiscActionType.GET_LIST_CAMPUS_AREA:
      return { ...state, campusArea: action.payload };
    case MiscActionType.GET_LIST_PLATFORM:
      return { ...state, platformList: action.payload };
    case MiscActionType.GET_LIST_MAJOR:
      return { ...state, majorList: action.payload };

    default:
      return { ...state };
  }
};

const MiscCommand = {
  getCampusAreaList: () => {
    return (dispatch: any) => {
      fetchAxios({
        url: `${environments.api.register}/ListCampusArea?page=1&take=100`,
        method: "get",
      }).then((response) => {
          const res = response.data?.data?.rows;
          dispatch({
            type: MiscActionType.GET_LIST_CAMPUS_AREA,
            payload: res,
          });
      });
    };
  },
  getPlatformList: () => {
    return (dispatch: any) => {
      fetchAxios({
        url: `${environments.api.misc}/Platform?SortDirection=true&OrderBy=name`,
        method: "get",
      }).then((response) => {
          const res = response.data?.data?.rows;
          dispatch({
            type: MiscActionType.GET_LIST_PLATFORM,
            payload: res,
          });
      });
    };
  },
  getMajorList: (campusAreaId: string) => {
    return (dispatch: any) => {
      fetchAxios({
        url: `${environments.api.register}/ListMajor?CampusAreaId=${campusAreaId}&page=1&take=100`,
        method: "get",
      }).then((response) => {
          const res = response.data?.data?.rows;
          dispatch({
            type: MiscActionType.GET_LIST_MAJOR,
            payload: res,
          });
      });
    };
  },
};

export { MiscReducer, MiscCommand };
