/* eslint-disable no-useless-concat */
import fetchAxios from "../../helpers/fetchAxios";
import { LISTPRIVACY, LISTTNC } from "./typeAction";

type TListTNC = {
  path: string;
  callBack?: any;
};

export const getListTNC = (props: TListTNC) => {
  const { path, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
    }).then((response) => {
      const res = response.data?.data;
      callBack(res);
      dispatch({
        type: LISTTNC,
        payload: res,
      });
    });
  };
};

export const getListPrivacy = (props: TListTNC) => {
  const { path, callBack } = props;
  return (dispatch: any) => {
    fetchAxios({
      url: path,
      method: "get",
    }).then((response) => {
      const res = response.data?.data;
      callBack(res);
      dispatch({
        type: LISTPRIVACY,
        payload: res,
      });
    });
  };
};
