import { Navigate } from "react-router-dom";
import { AUTH_SIGNIN } from "./listRoute";
import Cookies from "js-cookie";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  let auth = Cookies.get("tokenY");
  // replace not back page
  if (!auth) {
    return <Navigate to={AUTH_SIGNIN} replace />;
  }

  return children;
};

export default ProtectedRoute;
