import React from "react";
import { FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

import Buttons from "../../components/buttons";
import { START_PROJECT } from "../../routes/listRoute";

export default function HeaderBanner() {
  let navigate = useNavigate();
  const handleStartProject = () => {
    navigate(START_PROJECT);
  };
  return (
    <div className="md:pl-12 lg:pl-24 xl:pl-[8.25rem] py-10 lg:py-[8.25rem] bg-gradient-to-r from-[#0097DA] to-[#174479] lg:bg-[url('/src/assets/BInus/brands/brand-w-icons.svg')] bg-cover bg-center bg-no-repeat">
      <div className="flex items-center">
        <div className="w-full sm:w-[476px]">
          <p className="text-center md:text-left px-8 md:px-0 font-extrabold text-4xl md:text-[48px] text-white mb-4">
            Grow Your Business With Us.
          </p>
          <p className="text-center md:text-left px-8 md:px-0 text-sm text-white">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum sunt id
            magni quas rerum enim pariatur soluta sint laborum neque recusandae
            a accusamus eum optio, nostrum tempora vero natus voluptas.
          </p>
          <div className="flex flex-wrap justify-center sm:justify-start gap-6 items-center mt-8">
            <Buttons
              label="Start Project"
              className=" bg-transparent border-2 border-white text-white w-[172px] hover:bg-transparent"
              onClick={handleStartProject}
            />
            <div className="flex items-center">
              <p className="text-[#FFFFFF] text-xs">See All Influencers</p>
              <span className="pl-2">
                <FiChevronRight className="text-[#FFFFFF]" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
