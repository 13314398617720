import Cookies from "js-cookie"
import Axios from "axios"
import { toast } from "react-toastify"
import { AUTH_SIGNIN } from "../routes/listRoute"

interface TFetchAxios {
  url: string
  method: string
  body?: any
  token?: string
  showError?: boolean
  params?: any
}

// let tokens =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IjFkODhlNTY3LTZkMjgtNGI2Zi04MjE3LWUwODUzYjJjYTU4OCIsIkZ1bGxOYW1lIjoiTmF5YSBBbmRpbmkiLCJOaW0iOiI3NjY3ODMyMyIsIkVtYWlsIjoibmF5YUBtYWlsaW5hdG9yLmNvbSIsIlBob25lTnVtYmVyIjoiODg5NzM2ODYzNDQiLCJQcm9maWxlIjoiaHR0cDovL2JpbnVzZmx1ZW5jZXItYXBpLmRlbW9hcHAueHl6L1JlcG8vVXNlci9TY3JlZW5zaG90XzIwOS5wbmciLCJuYmYiOjE2NzcxMjMyNjMsImV4cCI6MTY3OTcxNTI2MywiaWF0IjoxNjc3MTIzMjYzLCJpc3MiOiJodHRwOi8vYmludXNmbHVlbmNlci1hcGkuZGVtb2FwcC54eXovIiwiYXVkIjoiaHR0cDovL2JpbnVzZmx1ZW5jZXItYXBpLmRlbW9hcHAueHl6LyJ9.QA1dk3nvZcsR5nSrRyl9ENhugCFMumUoqBK6JKWXI-k";

const fetchAxios = async (props: TFetchAxios) => {
  const { url, method, body, token, showError = true, params } = props

  if (token) {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
  }

  try {
    const response: any = await Axios({
      method: method,
      url: url,
      data: body,
      params,
    })
    if (response.status <= 299) {
      return Promise.resolve(response)
    } else if (response.status === 400) {
      if (showError) toast.error(response.message)
      window.location.href = "/404"
      return Promise.resolve(response)
    } else if (response.status === 500) {
      if (showError) toast.error(response.message)
    } else if (response.status === 404) {
      if (showError) toast.error(response.message)
    } else if (response.status === 401) {
      if (showError) toast.error(response.message)
      Cookies.remove("tokenY")
      Cookies.remove("userObj")
      window.location.href = AUTH_SIGNIN
    } else if (response.status === 403) {
      if (showError) toast.error(response.message)
    } else {
      if (showError) toast.error(response.message)
    }
  } catch (err: any) {
    const error = err.toJSON()
    if (error.status === 422) {
      if (showError) toast.error(err.response?.data?.errorMessage)
      Cookies.remove("facebook")
      Cookies.remove("linkedin")
      Cookies.remove("category")
      Cookies.remove("personal")
      return Promise.resolve(error)
    } else if (error.status === 401) {
      Cookies.remove("tokenY")
      Cookies.remove("userObj")
      window.location.href = AUTH_SIGNIN
    } else if (error.status === 415) {
      if (showError) toast.error(error.message)
      return Promise.resolve(error)
    } else if (error.status === 500) {
      if (showError)
        toast.error(err.response?.data?.errorMessage || error.message)
      Cookies.remove("facebook")
      Cookies.remove("linkedin")
      Cookies.remove("category")
      Cookies.remove("personal")
    } else if (error.status === 403) {
      if (showError) toast.error(err.response?.data?.errorMessage)
    }
    return Promise.resolve(error)
  }
}

export const axiosAlumni = () =>
  Axios.create({
    baseURL: process.env.REACT_APP_ALUMNI_URL,
    headers: {
      API_KEY: process.env.REACT_APP_ALUMNI_API_KEY,
    },
  })

export default fetchAxios
