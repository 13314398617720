import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Forgot from "../pages/auth/forgot";
import Campaign from "../pages/campaign";
import DetailCampaign from "../pages/campaign/detailCampaign";
import OurCommunity from "../pages/community";
import Faq from "../pages/faq";
import ForBinusFluencer from "../pages/forBinusFluencer";
import ForBrand from "../pages/forBrands";
import StartProject from "../pages/startProject";
import TandC from "../pages/TandC";
import PrivacyPolicy from "../pages/TandC/privacyPolicy";
import HowToRegister from "../pages/TandC/howToRegister";

const Register = lazy(() => import("../pages/auth/register"));
const Signin = lazy(() => import("../pages/auth"));
const ResetPassword = lazy(() => import("../pages/auth/resetPassword"));
const VerificationCode = lazy(() => import("../pages/auth/verification"));
const Home = lazy(() => import("../pages/home/index"));
const Profile = lazy(() => import("../pages/profile"));

export const AUTH_SIGNIN = `/auth/signin`;
export const AUTH_VERIFICATION_CODE = `/auth/verification/:email`;
export const AUTH_RESET_PASSWORD = `/auth/reset-password`;
export const AUTH_REGISTER = `/auth/register`;
export const AUTH_REGISTER_CODE = `/auth/register/:code`;
export const AUTH_FORGOT = `/auth/forgot`;
export const HOME = `/home`;
export const FORBINUSFLUENCER = `/for-binus-fluencer`;
export const PROFILE_DASHBOARD = `/profile/dashboard`;
export const PROFILE_JOB = `/profile/job`;
export const PROFILE = `/profile/:type`;
export const OFFER_DETAIL = `/offer/detail`;
export const CAMPAIGN = `/campaign`;
export const CAMPAIGN_DETAIL = `/campaign/:id`;
export const OUR_COMMUNITY = `/community`;
export const FOR_BRAND = `/for-brand`;
export const FOR_BINUSFLUENCER = `/for-binusfluencer`;
export const TANDC = `/terms-and-conditions`;
export const PRIVACYPOLICY = `/privacy-policy`;
export const FAQ = `/faq`;
export const START_PROJECT = `/start-project`;
export const HOW_TO_REGISTER = '/how-to-join-us';

export const menuRoutes = [
  {
    path: HOME,
    element: Home,
    title: "Home",
    content: "home",
  },
  {
    path: FORBINUSFLUENCER,
    element: ForBinusFluencer,
    title: "Binus",
    content: "Binus",
  },
  {
    path: OFFER_DETAIL,
    element: DetailCampaign,
    title: "offer Detail",
    content: "offer detail",
  },
  {
    path: OUR_COMMUNITY,
    element: OurCommunity,
    title: "Campaign",
    content: "campaign",
  },
  {
    path: TANDC,
    element: TandC,
    title: "trems and conditions",
    content: "campaign",
  },
  {
    path: PRIVACYPOLICY,
    element: PrivacyPolicy,
    title: "Privacy Policy",
    content: "campaign",
  },
  {
    path: FAQ,
    element: Faq,
    title: "Faq",
    content: "Faq",
  },
  {
    path: FOR_BRAND,
    element: ForBrand,
    title: "For Brand",
    content: "For Brand ",
  },
  {
    path: HOW_TO_REGISTER,
    element: HowToRegister,
    title: "How To Register",
    content: "campaign",
  },
  {
    path: "/",
    element: () => <Navigate to={HOME} />,
    title: "Signin",
    content: "signin binus fluencer",
  },
];

export const privateRoutes = [
  {
    path: PROFILE,
    element: Profile,
    title: "profile",
    content: "profile",
  },
  {
    path: CAMPAIGN,
    element: Campaign,
    title: "Campaign",
    content: "campaign",
  },
  {
    path: CAMPAIGN_DETAIL,
    element: DetailCampaign,
    title: "Campaign",
    content: "campaign",
  },
];

export const publicRoutes = [
  {
    path: AUTH_SIGNIN,
    element: Signin,
    title: "sign in ",
    content: "sign in",
  },
  {
    path: AUTH_VERIFICATION_CODE,
    element: VerificationCode,
    title: "Verification",
    content: "verification",
  },
  {
    path: AUTH_RESET_PASSWORD,
    element: ResetPassword,
    title: "Verification",
    content: "verification",
  },
  {
    path: AUTH_REGISTER,
    element: Register,
    title: "Verification",
    content: "verification",
  },
  {
    path: AUTH_REGISTER_CODE,
    element: Register,
    title: "Verification",
    content: "verification",
  },
  {
    path: AUTH_FORGOT,
    element: Forgot,
    title: "Verification",
    content: "verification",
  },
  {
    path: START_PROJECT,
    element: StartProject,
    title: "For Brand",
    content: "For Brand ",
  },
];
