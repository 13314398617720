import Cookies from "js-cookie";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "../pages/404";
import Layout from "../pages/Layout";
import { HOME, menuRoutes, privateRoutes, publicRoutes } from "./listRoute";
import ProtectedRoute from "./protectedRoute";
import { usePageTracking } from "../hooks/hooks";

export default function Routers() {
  usePageTracking();

  return (
    <Routes>
      {menuRoutes.map((value: any, i: any) => {
        const { element: Element } = value;
        return (
          <Route
            key={i}
            path={value.path}
            element={
              <Layout>
                <Element />
              </Layout>
            }
          />
        );
      })}
      {publicRoutes.map((val: any, i: any) => {
        const { element: Element } = val;
        let auth = Cookies.get("tokenY");
        return (
          <Route
            key={i}
            path={val.path}
            element={!auth ? <Element /> : <Navigate to={HOME} replace />}
          />
        );
      })}
      {privateRoutes.map((value: any, i: any) => {
        const { element: Element } = value;
        return (
          <Route
            key={i}
            path={value.path}
            element={
              <ProtectedRoute>
                <Layout>
                  <Element />
                </Layout>
              </ProtectedRoute>
            }
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
