import React, { useEffect } from "react";
import SectionFive from "./sectionFive";
import SectionOne from "./sectionOne";
import SectionTree from "./sectionTree";
import SectionTwo from "./sectionTwo";

export default function ForBinusFluencer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <SectionOne />
      <SectionTwo />
      <SectionTree />
      {/* pake component home */}
      <SectionFive />
    </>
  );
}
